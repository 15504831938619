/* eslint-disable */
import { CountryCode } from "libphonenumber-js";

import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import Keyboard from "react-simple-keyboard";

type PhoneFormInputType = {
    formik: any;
};
export default function PhoneFormInput({
    formik,
}: PhoneFormInputType): JSX.Element {
    const [codeContry, setCodeContry] = React.useState<CountryCode>();
    const [phoneInput, setPhoneInput] = React.useState("+33");
    const onChangePhoneInput = (input: string): void => {
        if (!input.startsWith((codeContry as any).dialCode)) {
            input =
                (codeContry as any).dialCode +
                input.replace(
                    new RegExp(`^\\${(codeContry as any).dialCode}`),
                    ""
                );
        }
        setPhoneInput(input);
        formik.setFieldValue("phoneNumber", input, true);
    };

    function handleKeyboardOnKeyPressEvent(button: string) {
        if (button === "{enter}") {
            formik.handleSubmit();
        }
    }
    return (
        <div className="phone-input-container">
            <div className="phone-input">
                <PhoneInput
                    isValid={(inputNumber: any, country: any) => {
                        if (inputNumber.match(/1234/)) {
                            return false;
                        } else {
                            setCodeContry(country);
                            return true;
                        }
                    }}
                    buttonStyle={{
                        width: "15%",
                        borderRadius: "10px 0 0 10px",
                    }}
                    inputStyle={{
                        width: "93%",
                        height: "3vh",
                        border: "2px solid #00000",
                        borderRadius: "20px",
                        fontSize: "30px",
                    }}
                    specialLabel=""
                    disableDropdown={false}
                    country={"fr"}
                    value={phoneInput}
                    onFocus={() => setPhoneInput(phoneInput)}
                    inputClass="input-phone-style"
                />
                {formik.touched["phoneNumber"] &&
                formik.errors["phoneNumber"] ? (
                    <div style={{ color: "red", textAlign: "center" }}>
                        {formik.errors["phoneNumber"]}
                    </div>
                ) : null}
            </div>
            <Keyboard
                useButtonTag={true}
                theme="numeric-keyboard__clz hg-theme-default hg-layout-numeric numeric-theme"
                display={{
                    "{bksp}": "&#10006",
                    "{enter}": "&#10003",
                }}
                layoutName="default"
                onChange={onChangePhoneInput}
                onKeyPress={handleKeyboardOnKeyPressEvent}
                disableCaretPositioning={true}
                disableRowButtonContainers={true}
                layout={{
                    default: ["1 2 3", "4 5 6", "7 8 9", "{bksp} 0 {enter}"],
                }}
            />
        </div>
    );
}
