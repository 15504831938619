import { BASIC_COMPOSITION_TYPE } from "@constants";
import classname from "classnames";
import { t } from "i18next";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { getFilenameFromUrl } from "@helpers/general";

import MessageIcon from "@components/OrderTaking/Components/Icons/MessageIcon";
import { MenuCompositionsLabels } from "@components/OrderTaking/Components/Products/Cart/MenuCompositionsLabels";
import {
    getItemComposition,
    getItemCompositionLabels,
    refactorWorkflowData,
} from "@components/OrderTaking/Helpers";
import { imageWithFallback } from "@components/OrderTaking/Helpers/Commonfunction";
import {
    setIsCommentKeyboardActive,
    setWorkflowData,
} from "@components/OrderTaking/Store/feature";
import { EditButton } from "@components/SvgIcons";

import { kioskStore } from "@pages/Kiosk/store";

import ShowDetailsDialog from "./ShowDetailsDialog";

export function ShowCardProduct({ item }) {
    const itemRef = React.useRef();
    const dispatchStore = useDispatch();
    const {
        isCommentProductActive,
        isProductModificationActive,
        isShownProductCompositionDescription,
    } = useSelector((state) => state.settingSlice.productSetting, shallowEqual);
    const { globalcard } = useSelector(
        (state) => state.orderSlice,
        shallowEqual
    );
    const { devise } = useSnapshot(kioskStore);
    const orderWorkflowItemSlice = useSelector(
        (state) => state.orderWorkflowItemSlice,
        shallowEqual
    );
    const { workflowData } = useSelector(
        (state) => state.workFlowDataSlice,
        shallowEqual
    );
    const [isBackActive, setIsBackActive] = React.useState(false);
    const [isShowDetailsOpened, setIsShowDetailsOpened] = React.useState(false);
    const productTitle =
        item.name !== BASIC_COMPOSITION_TYPE
            ? item.displayTitle
            : t("BASIC COMPOSITION").toUpperCase();

    const [title, setTitle] = React.useState(
        item.name !== BASIC_COMPOSITION_TYPE
            ? item.displayTitle
            : t("BASIC COMPOSITION").toUpperCase()
    );
    const [path, setPath] = React.useState(item.path);

    const listworkflow = workflowData[workflowData.length - 1];
    const canEditItem =
        listworkflow &&
        listworkflow?.workflow[listworkflow.index]?.NbrOfChoices >=
            listworkflow?.workflow[listworkflow.index]?.minNbrOfChoices;

    const itemsCompositionView =
        item?.compositions &&
        Object.keys(item?.compositions).length > 0 &&
        item.type !== BASIC_COMPOSITION_TYPE
            ? getItemComposition(item?.compositions)
            : [];
    const itemsCompositionViewlabels =
        item?.compositions &&
        Object.keys(item?.compositions).length > 0 &&
        item.type !== BASIC_COMPOSITION_TYPE
            ? getItemCompositionLabels(item?.compositions)
            : item.type === BASIC_COMPOSITION_TYPE
            ? getItemCompositionLabels(item?.compositions)
            : [];
    const itemPreviewImage =
        item.type !== BASIC_COMPOSITION_TYPE
            ? getFilenameFromUrl(item.urlImage, "catalog", "Items")
            : item.urlImage;

    function handleModifyProductOnCLickEvent() {
        if (!canEditItem) return;

        const workflowModify = refactorWorkflowData(
            item.workflow,
            orderWorkflowItemSlice.item.shopCart,
            globalcard.items
        );
        dispatchStore(setWorkflowData([...workflowModify]));
    }

    function handleShowDetailOnClickEvent() {
        setIsShowDetailsOpened(!isShowDetailsOpened);
        setPath(item.path);
        setIsBackActive(false);
    }

    React.useEffect(() => {
        if (item.type === BASIC_COMPOSITION_TYPE) {
            return;
        }
        itemRef.current &&
            itemRef.current?.classList.add("shopcart-product-animation");

        let timer = setTimeout(() => {
            itemRef.current &&
                itemRef.current?.classList.remove("shopcart-product-animation");
        }, 1200);
        return () => clearTimeout(timer);
    }, [item.quantity]);
    function handleAddCommentOnclickEvent() {
        dispatchStore(
            setIsCommentKeyboardActive({
                active: true,
                product: item,
            })
        );
    }

    return (
        <React.Fragment>
            {isShowDetailsOpened && (
                <ShowDetailsDialog
                    open={isShowDetailsOpened}
                    onClose={handleShowDetailOnClickEvent}
                    path={path}
                    title={title}
                    item={item}
                    setTitle={setTitle}
                    setPath={setPath}
                    setIsBackActive={setIsBackActive}
                    isBackActive={isBackActive}
                    handleAddCommentOnclickEvent={handleAddCommentOnclickEvent}
                />
            )}

            <div
                ref={itemRef}
                className={`d-flex flex-column justify-content-start align-items-center py-2`}
                style={{
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    boxShadow: "0px 3px 6px #0000001A",
                    border: "1px solid  #E5E5E5",
                    borderRadius: "12px",
                    height: "219px",
                    width:
                        item.compositions !== undefined || item.price > 0
                            ? "334px"
                            : "195px",
                }}
            >
                <div
                    className="d-flex align-items-center px-2"
                    style={{
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <div
                        className="d-flex align-items-center flex-column"
                        style={{ width: "100%", gap: "5px" }}
                    >
                        <div
                            className="d-flex align-items-start"
                            style={{
                                width: "100%",
                                justifyContent:
                                    item.price > 0 ? "space-between" : "center",
                            }}
                        >
                            <img
                                className="cart-image-product__clz"
                                src={itemPreviewImage}
                                alt={itemPreviewImage}
                                onError={imageWithFallback}
                            />
                            {item.price > 0 && (
                                <div className="price-product-preview">
                                    {item.price.toFixed(devise.decimalPrice) +
                                        ` ${devise.deviseSymbole}`}
                                </div>
                            )}
                        </div>
                        <div
                            className="title-product-details two-lines-preview-text"
                            style={{
                                font: "normal normal bold 16px/21px Segoe UI",
                                textAlign: "center",
                            }}
                        >
                            {item.quantity > 1 ? (
                                <span
                                    style={{
                                        color: "var(--global-color)",
                                    }}
                                >
                                    {item.quantity > 0
                                        ? item.quantity + "X "
                                        : ""}
                                </span>
                            ) : null}
                            <span>
                                {item.name !== BASIC_COMPOSITION_TYPE
                                    ? item.displayTitle
                                    : t("BASIC COMPOSITION").toUpperCase()}
                            </span>
                        </div>
                        {item.selectedOptions?.length > 0 && (
                            <span
                                className="preview-text"
                                style={{ color: "#b7b7b7" }}
                            >
                                {item.selectedOptions.join(",")}
                            </span>
                        )}

                        {isShownProductCompositionDescription &&
                        itemsCompositionViewlabels.length > 0 ? (
                            <MenuCompositionsLabels
                                compositions={itemsCompositionViewlabels}
                                shopCart={false}
                            />
                        ) : itemsCompositionView?.length > 0 ? (
                            <span className="product-details__clz preview-text">
                                {itemsCompositionView.join(", ")}
                            </span>
                        ) : null}
                    </div>
                </div>
                <div
                    className={classname(
                        "d-flex justify-content-center align-items-center ",
                        {
                            "disable-edit": !canEditItem,
                        }
                    )}
                >
                    {item.compositions !== undefined &&
                    Object.keys(item.compositions).length > 0 ? (
                        <div className="d-flex" style={{ gap: "5px" }}>
                            {isShownProductCompositionDescription ? null : (
                                <>
                                    <button
                                        className="d-flex justify-content-center align-items-center  see-details"
                                        onClick={() => {
                                            setPath(item.path);
                                            setIsShowDetailsOpened(
                                                !isShowDetailsOpened
                                            );
                                            setTitle(productTitle);
                                        }}
                                    >
                                        {t("See details")}
                                    </button>{" "}
                                    {isCommentProductActive === true &&
                                    item.haveComment === true ? (
                                        <div
                                            className="d-flex justify-content-center align-content-center p-1"
                                            style={{
                                                backgroundColor:
                                                    item.comment !== ""
                                                        ? "var(--global-color)"
                                                        : "gray",

                                                width: "53px",
                                                height: "53px",

                                                borderRadius: " 10px",
                                            }}
                                            onClick={
                                                handleAddCommentOnclickEvent
                                            }
                                        >
                                            <MessageIcon
                                                fill="#FFF"
                                                width="45"
                                                height="45"
                                            />
                                        </div>
                                    ) : null}
                                </>
                            )}
                        </div>
                    ) : (
                        <div
                            className="d-flex align-items-center"
                            style={{ gap: "5px" }}
                        >
                            {isProductModificationActive ? (
                                <div
                                    onClick={() => {
                                        setIsShowDetailsOpened(false);
                                        handleModifyProductOnCLickEvent();
                                    }}
                                >
                                    <EditButton />
                                </div>
                            ) : null}
                            {isCommentProductActive === true &&
                            item.haveComment === true ? (
                                <div
                                    className="d-flex justify-content-center align-content-center p-1"
                                    style={{
                                        backgroundColor:
                                            item.comment !== ""
                                                ? "var(--global-color)"
                                                : "gray",

                                        width: "55px",
                                        height: "55px",

                                        borderRadius: " 10px",
                                    }}
                                    onClick={handleAddCommentOnclickEvent}
                                >
                                    <MessageIcon
                                        fill="#FFF"
                                        width="45"
                                        height="45"
                                    />
                                </div>
                            ) : null}
                        </div>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
}
export const ShowCardProductMemo = React.memo(ShowCardProduct);
ShowCardProduct.propTypes = {
    item: PropTypes.object,
};
