import {
    BASIC_COMPOSITION,
    BASIC_COMPOSITION_TYPE,
    FIXED_COMPOSITION_TYPE,
} from "@constants";
import { t } from "i18next";
import { snapshot } from "valtio";

import { getImageByLanguage } from "@components/OrderTaking/Helpers";
import {
    addWorkFlowItemShopCart,
    SelectProduct,
    setWorkflowData,
} from "@components/OrderTaking/Store/feature";

import { kioskStore } from "@pages/Kiosk/store";

export function NextOnClickEvent(
    listworkflow,
    AddorderBasicCompositionWorkflow,
    addIndexstep,
    NextStep
) {
    if (
        listworkflow.workflow[listworkflow.index]?.NbrOfChoices <
        listworkflow.workflow[listworkflow.index]?.minNbrOfChoices
    )
        return;
    if (listworkflow) {
        if (
            listworkflow.workflow[listworkflow.index].type === BASIC_COMPOSITION
        ) {
            AddorderBasicCompositionWorkflow();
        }
        if (listworkflow.step > listworkflow.index) {
            addIndexstep(listworkflow);
        } else {
            NextStep(listworkflow);
        }
    }
}

export function AddOrderBasicCompositionWorkflow(
    workflowData,
    dispatch,
    globalcard
) {
    const { customerLanguage } = snapshot(kioskStore);
    let listProduct =
        workflowData[workflowData.length - 1].workflow[
            workflowData[workflowData.length - 1]?.index
        ].Items;
    const stepPath =
        workflowData[workflowData.length - 1].workflow[
            workflowData[workflowData.length - 1]?.index
        ].path;

    const path = [
        ...stepPath,
        {
            name: BASIC_COMPOSITION_TYPE,
            iuud: BASIC_COMPOSITION_TYPE,
            type: BASIC_COMPOSITION_TYPE,
        },
    ];

    //! to review refactor
    const listItems = listProduct.reduce((prev, curr) => {
        if (
            !curr.basicCompositionActive ||
            (curr.isObligatory && !curr.outOfStock)
        ) {
            return {
                ...prev,
                [curr.isObligatory
                    ? curr.title
                    : `${t("WITHOUT")} ` + curr.title]: {
                    name: curr.isObligatory
                        ? curr.title
                        : `${t("WITHOUT")} ` + curr.title,
                    designation: curr.isObligatory
                        ? curr.title
                        : `SANS ${curr.title}`,
                    urlImage: curr?.url_image
                        ? curr?.url_image?.[customerLanguage.language]
                            ? curr.url_image?.[customerLanguage.language]
                            : getImageByLanguage(curr.url_image, "steps")
                        : curr.img,
                    displayTitle: curr.isObligatory
                        ? curr.displayTitle
                        : `${t("WITHOUT")} ` + curr.displayTitle,
                    type: curr.isObligatory
                        ? FIXED_COMPOSITION_TYPE
                        : BASIC_COMPOSITION,
                    quantity: 1,
                    stepRank: 0,
                    path: [...curr.path],
                    categoryParent: globalcard.categories[curr.parent]?.parent
                        ? globalcard.categories[curr.parent].parent
                        : curr.parent,
                    categoryTitle: curr.parent
                        ? globalcard.categories[curr.parent]?.parent
                            ? globalcard.categories[
                                  globalcard.categories[curr.parent].parent
                              ].title
                            : globalcard.categories[curr.parent].title
                        : "",
                    categoryVisibility: curr.parent
                        ? globalcard.categories[curr.parent]?.KioskVisibility
                        : {},

                    subcategoryTitle: curr.parent
                        ? globalcard.categories[curr.parent]?.parent
                            ? globalcard.categories[curr.parent].title
                            : ""
                        : "",
                    reference: curr.ref,
                    iuud: curr.iuud,
                    price: 0,
                    tva: curr.tva,
                    modifier: curr?.modifier,
                    fid: curr.fid ? curr.fid : 0,
                    totalFidelity: curr.fid ? curr.fid : 0,
                    workflow: [...workflowData],
                    date: Date.now(),
                    advancedItemPrice: curr.price,
                    productVisibility: curr.KioskVisibility,
                    unity: curr.unity !== undefined ? curr.unity : "",
                    isSubProduct: false,
                },
            };
        }
        return {
            ...prev,
        };
    }, {});

    let data = {
        [BASIC_COMPOSITION_TYPE]: {
            type: BASIC_COMPOSITION_TYPE,
            urlImage: "./images/iconecompobase.png",
            price: 0,
            path,
            workflow: workflowData,
            compositions: Object.values(listItems)
                .sort((a) => (a.type === FIXED_COMPOSITION_TYPE ? -1 : 1))
                .reduce((prev, curr) => {
                    return { ...prev, [curr.displayTitle]: curr };
                }, {}),
            stepRank: 0,
        },
    };

    dispatch(addWorkFlowItemShopCart(data[BASIC_COMPOSITION_TYPE]));
}

export function AutoValidateStep(dispatch, workflowData, additionalSale) {
    if (
        workflowData.length > 1 &&
        workflowData[workflowData.length - 1].workflow[
            workflowData[workflowData.length - 1].index
        ].NbrOfChoices ===
            workflowData[workflowData.length - 1].workflow[
                workflowData[workflowData.length - 1].index
            ].maxNbrOfChoices &&
        !workflowData[workflowData.length - 1].workflow[
            workflowData[workflowData.length - 1].index
        ].haveComment
    ) {
        let copyworkflowData = JSON.parse(JSON.stringify(workflowData));
        copyworkflowData.splice(copyworkflowData.length - 1, 1);
        const copyDataworkflow = [...copyworkflowData];
        if (
            copyworkflowData[copyDataworkflow.length - 1].step >
                copyworkflowData[copyDataworkflow.length - 1].index &&
            workflowData[workflowData.length - 1].workflow[
                workflowData[workflowData.length - 1].index
            ].maxNbrOfChoices ===
                workflowData[workflowData.length - 1].workflow[
                    workflowData[workflowData.length - 1].index
                ].NbrOfChoices &&
            workflowData[workflowData.length - 1].workflow[
                workflowData[workflowData.length - 1].index
            ].maxNbrOfChoices > 0 &&
            copyworkflowData[copyDataworkflow.length - 1].workflow[
                copyworkflowData[copyDataworkflow.length - 1].index
            ].NbrOfChoices ===
                copyworkflowData[copyDataworkflow.length - 1].workflow[
                    copyworkflowData[copyDataworkflow.length - 1].index
                ].maxNbrOfChoices &&
            additionalSale.listItems.length === 0
        ) {
            copyworkflowData[copyDataworkflow.length - 1].index += 1;
        }

        if (
            workflowData[workflowData.length - 1].workflow[
                workflowData[workflowData.length - 1].index
            ].type !== BASIC_COMPOSITION
        ) {
            dispatch(setWorkflowData(copyworkflowData));
        }
    }

    // ! validate automatic of menu
    if (workflowData.length === 1) {
        let copyworkflowData = JSON.parse(JSON.stringify(workflowData));
        const copyDataworkflow = [...copyworkflowData];

        if (
            workflowData.length === 1 &&
            workflowData[workflowData.length - 1].workflow[
                workflowData[workflowData.length - 1].index
            ].haveComment === false &&
            workflowData[workflowData.length - 1].workflow[
                workflowData[workflowData.length - 1].index
            ]?.NbrOfChoices ===
                workflowData[workflowData.length - 1].workflow[
                    workflowData[workflowData.length - 1].index
                ]?.maxNbrOfChoices &&
            workflowData[workflowData.length - 1].workflow[
                workflowData[workflowData.length - 1].index
            ]?.maxNbrOfChoices > 0 &&
            copyworkflowData[copyDataworkflow.length - 1].step ===
                copyworkflowData[copyDataworkflow.length - 1].index &&
            additionalSale.listItems.length === 0
        ) {
            dispatch(setWorkflowData([]));
            dispatch(SelectProduct({}));
        }
    }
}
