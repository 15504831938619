import { useSnapshot } from "valtio";

import React from "react";
import { useTranslation } from "react-i18next";

import { kioskStore as store } from "@pages/Kiosk/store";

import "./index.css";
import NetworkErrorCashMachine from "./NetworkErrorCashMachine";
import RefundCashMachineModal from "./refundCashMachineModal";

export default function MainContent(): JSX.Element {
    const { cashMachineStatus } = useSnapshot(store);
    const { t } = useTranslation();

    return (
        <div className="text-container text-center">
            {cashMachineStatus.isNotAvailable ? (
                <span>{t("Cash machine not available")}</span>
            ) : null}
            {cashMachineStatus.isOccupied ? (
                <span>{t("Cash machine occupied")}</span>
            ) : null}
            {cashMachineStatus.isCoinsRefund ||
            cashMachineStatus.isRefundProblem ? (
                <RefundCashMachineModal />
            ) : null}
            {cashMachineStatus.isNetworkProblem ? (
                <NetworkErrorCashMachine />
            ) : null}
        </div>
    );
}
