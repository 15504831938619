import { BASIC_COMPOSITION_TYPE } from "@constants";
import { t } from "i18next";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { getFilenameFromUrl } from "@helpers/general";

import { refactorWorkflowData } from "@components/OrderTaking/Helpers";
import { imageWithFallback } from "@components/OrderTaking/Helpers/Commonfunction";
import {
    setIsInternalModification,
    setProductBeforeUpdate,
    setWorkflowData,
} from "@components/OrderTaking/Store/feature";
import { EditButton } from "@components/SvgIcons";

import { kioskStore } from "@pages/Kiosk/store";

export function ShowDetailsCartProduct({
    item,
    setPath,
    setTitle,
    onClose,
    setIsBackActive,
}) {
    const dispatchStore = useDispatch();
    const { devise } = useSnapshot(kioskStore);

    const orderWorkflowItemSlice = useSelector(
        (state) => state.orderWorkflowItemSlice,
        shallowEqual
    );

    const { globalcard } = useSelector(
        (state) => state.orderSlice,
        shallowEqual
    );
    const { isProductModificationActive } = useSelector(
        (state) => state.settingSlice.productSetting,
        shallowEqual
    );
    let productComposition = [];
    let productBasicComposition = [];

    if (item?.compositions?.[BASIC_COMPOSITION_TYPE]) {
        Object.keys(
            item?.compositions?.[BASIC_COMPOSITION_TYPE].compositions
        ).forEach((key) => {
            const itemBasicComposition = `${item?.compositions?.[BASIC_COMPOSITION_TYPE].compositions[key].displayTitle} `;

            productBasicComposition.push(itemBasicComposition);
        });
    }
    if (item?.compositions) {
        Object.keys(item?.compositions).forEach((key) => {
            if (key !== BASIC_COMPOSITION_TYPE) {
                let quantity = item?.compositions[key].quantity;
                const itemComposition = `${quantity}x ${item?.compositions[key].displayTitle} `;
                productComposition.push(itemComposition);
            }
        });
    }

    function handleModifyProductOnCLickEvent() {
        const workflowModify = refactorWorkflowData(
            item.workflow,
            orderWorkflowItemSlice.item.shopCart,
            globalcard.items
        );
        dispatchStore(
            setProductBeforeUpdate({ ...orderWorkflowItemSlice.item })
        );
        dispatchStore(setWorkflowData([...workflowModify]));
        dispatchStore(setIsInternalModification(true));
        onClose();
    }
    const handleShowDetailsClick = () => {
        if (item?.path?.length > 0) {
            setPath(item.path);
            setTitle(
                item.name !== BASIC_COMPOSITION_TYPE
                    ? item.displayTitle
                    : t("BASIC COMPOSITION").toUpperCase()
            );
            setIsBackActive(true);
        }
    };

    const itemPreviewImage =
        item.type !== BASIC_COMPOSITION_TYPE
            ? getFilenameFromUrl(item.urlImage, "catalog", "Items")
            : item.urlImage;

    return (
        <>
            <div
                className="d-flex flex-column justify-content-start align-items-center py-2"
                style={{
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    boxShadow: "0px 3px 6px #0000001A",
                    border: "1px solid #E5E5E5",
                    borderRadius: "12px",
                    height: "219px",
                    width:
                        item.compositions !== undefined || item.price > 0
                            ? "334px"
                            : "195px",
                }}
            >
                <div
                    className="d-flex align-items-center px-2"
                    style={{
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <div
                        className="d-flex align-items-center flex-column"
                        style={{ width: "100%", gap: "5px" }}
                    >
                        <div
                            className="d-flex align-items-start"
                            style={{
                                width: "100%",
                                justifyContent:
                                    item.price > 0 ? "space-between" : "center",
                            }}
                        >
                            <img
                                src={itemPreviewImage}
                                className="cart-image-product__clz"
                                alt={itemPreviewImage}
                                onError={imageWithFallback}
                            />
                            {item.price > 0 && (
                                <div className="price-product-preview">
                                    {item.price.toFixed(devise.decimalPrice) +
                                        ` ${devise.deviseSymbole}`}
                                </div>
                            )}
                        </div>
                        <div
                            className="title-product-details two-lines-preview-text"
                            style={{
                                font: "normal normal bold 16px/21px Segoe UI",
                                textAlign: "center",
                            }}
                        >
                            {item.quantity > 1 ? (
                                <span
                                    style={{
                                        color: "var(--global-color)",
                                    }}
                                >
                                    {item.quantity > 0
                                        ? item.quantity + "X "
                                        : ""}
                                </span>
                            ) : null}
                            <span>
                                {item.name !== BASIC_COMPOSITION_TYPE
                                    ? item.displayTitle
                                    : t("BASIC COMPOSITION").toUpperCase()}
                            </span>
                        </div>
                        {item.type !== BASIC_COMPOSITION_TYPE &&
                            (productBasicComposition.length > 0 && (
                                <span className="product-details__clz preview-text">
                                    {productBasicComposition.join(" ,")}
                                </span>
                            ),
                            productComposition.length > 0 && (
                                <span className="product-details__clz preview-text">
                                    {productComposition.join(" ,")}
                                </span>
                            ))}
                    </div>
                </div>
                <div className=" d-flex justify-content-center align-items-center">
                    {item.compositions !== undefined &&
                    Object.keys(item.compositions).length > 0 ? (
                        <button
                            className=" d-flex justify-content-center align-items-center see-details "
                            style={{
                                backgroundColor: "var(--global-color)",
                            }}
                            onClick={handleShowDetailsClick}
                        >
                            {t("See details")}
                        </button>
                    ) : isProductModificationActive ? (
                        <div
                            onClick={() => {
                                handleModifyProductOnCLickEvent(item);
                            }}
                        >
                            <EditButton />
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    );
}
ShowDetailsCartProduct.propTypes = {
    item: PropTypes.object,
    onClose: PropTypes.func,
    setPath: PropTypes.func,
    setTitle: PropTypes.func,
    setIsBackActive: PropTypes.func,
};
