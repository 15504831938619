import { BASIC_COMPOSITION_TYPE } from "@constants";
import moment from "moment";
import { snapshot } from "valtio";

import { generateItemPriceUuid } from "@helpers/general";

import { kioskStore } from "@pages/Kiosk/store";

const getIuud = (newList, productPrice) => {
    const uidPrice = generateItemPriceUuid(newList);
    if (
        productPrice?.advancedPrice[uidPrice] === undefined &&
        newList.length > 2
    ) {
        newList.length = newList.length - 2;
        return getIuud(newList);
    }
    return uidPrice;
};
export const getPrice = (listOptions, productPrice, isSchedulePrice) => {
    const listOptionsShallow = [...listOptions];
    const uidPrice = getIuud(listOptionsShallow, productPrice);

    const selectedPrice = getItemPriceTime(
        productPrice,
        uidPrice,
        isSchedulePrice
    );

    return {
        price:
            selectedPrice !== undefined ? selectedPrice : productPrice?.content,
        tva: productPrice?.advancedPrice[uidPrice]?.tva
            ? productPrice?.advancedPrice[uidPrice]?.tva
            : 0,
    };
};

export const getItemPriceWithOption = (
    indexOptionOfPrincipleProducts,
    optionsOfProduct,
    itemAdvancedPrice
) => {
    const { selectedSaleModeId } = snapshot(kioskStore);

    if (optionsOfProduct.length > 0) {
        const opt = optionsOfProduct[0].listOptions.filter((op) => {
            return op.optionIndex === indexOptionOfPrincipleProducts;
        });
        if (opt.length > 0) {
            const selectedOptionsUuid = [
                selectedSaleModeId,
                "KIOSK",
                optionsOfProduct[0].iuud,
                opt[0].iuud,
            ];
            const result = getPrice(
                [...selectedOptionsUuid],
                itemAdvancedPrice,
                false
            );
            return result;
        } else {
            return {
                price: 0,
                tva: 0,
            };
        }
    }
    return {
        price: 0,
        tva: 0,
    };
};
export const updateObject = (
    keyName,
    object,
    salesModeId,
    parentItem,
    totalParentItemPrice
) => {
    for (var key in object) {
        if (key === keyName) {
            let initialItemOption = [salesModeId, "KIOSK"];
            let newPrice = getPrice(
                initialItemOption,
                object.advancedItemPrice,
                true
            );
            if (object.withSpecialPrice) {
                newPrice.price = parseFloat(object.specialPrice);
            } else {
                newPrice.price = parseFloat(object.priceOfItemInStep);
                // if (
                //     parentItem?.selectedOptions.length > 0 &&
                //     object.options
                // ) {
                //     newPrice = getItemPriceWithOption(
                //         parentItem?.selectedOptionsUuid,
                //         object.options,
                //         object.advancedItemPrice,
                //         itemPrice
                //     );
                // }
            }

            totalParentItemPrice.totalItemPrice += newPrice.price;
            object[key] = newPrice.price;
            object["tva"] = newPrice.tva;
        } else {
            if (
                typeof object[key] === "object" &&
                key !== "workflow" &&
                key !== BASIC_COMPOSITION_TYPE
            ) {
                object[key] = updateObject(keyName, object[key]);
            }
        }
    }

    return object;
};

const getItemPriceTime = (productPrice, uidPrice, isSchedulePrice) => {
    const currentDateTime = moment().format("HH:mm");
    let selectedPrice = productPrice?.advancedPrice[uidPrice]?.ttc;

    try {
        if (
            isSchedulePrice &&
            productPrice?.isVisible &&
            productPrice?.advancedPrice[uidPrice] &&
            productPrice?.advancedPrice[uidPrice]?.schedule.length > 0
        ) {
            const scheduledPrices =
                productPrice?.advancedPrice[uidPrice]?.schedule;

            for (let period = 0; period <= scheduledPrices.length; period++) {
                const start = scheduledPrices[period]?.HeureDebut ?? "";
                const end = scheduledPrices[period]?.HeureFin ?? "";
                if (start <= currentDateTime && currentDateTime <= end) {
                    selectedPrice = Number(scheduledPrices[period]?.Price);
                    break;
                }
            }
        }
        return selectedPrice;
    } catch (error) {
        console.error("Error while getting Scheduled Price: ", error);
        return selectedPrice;
    }
};
