import { CATALOGUE_MODE } from "@constants";
import { snapshot } from "valtio";

import { store } from "@store";

import { syncStore } from "@components/Synchronization/store";

import {
    kioskStore,
    setActionsButtonsItems,
    setCashUuID,
    setChosenTransition,
    setCreditCardUuId,
    setInformationMessageItems,
    setIsActionButtonsActive,
    setIsAnimationIn,
    setIsHeaderActive,
    setIsInformationMessageActive,
    setIsLogoActive,
    setIsSubStepActionButtonsActive,
    setIsSubStepIconActive,
    setIsSubStepInformationMessageActive,
    setIsSubStepLogoActive,
    setIsSubStepTopBannerActive,
    setIsTopBannerActive,
    setIsUserAccountActive,
    setIsUserAuthenticated,
    setLoyaltyUuId,
    setMainContentItems,
    setSelectedAuthMode,
    setSubStepActionButtons,
    setSubStepIconId,
    setSubStepInformationMessageItems,
    setUserAccountMessageItems,
} from "@pages/Kiosk/store";

export function stepDataContent(
    navigationIndex: number,
    // eslint-disable-next-line
    kioskWays: any[]
): void {
    const {
        project,
        selectedSaleModeValue,
        selectedInformationMode,
        customerLanguage,
    } = snapshot(kioskStore);
    const { transitions } = snapshot(store);
    const currentStep = kioskWays[navigationIndex]?.name;
    const { peripheralShopApp } = snapshot(syncStore);
    switch (currentStep) {
        case "salesMethods": {
            let salesModes = (
                project.template.content.salesMethods as ProjectContentItemType
            ).items.filter(
                (salesMode) =>
                    salesMode.active &&
                    salesMode.languages[customerLanguage.name].content !== ""
            );

            if (process.env.REACT_APP_MODE === CATALOGUE_MODE) {
                salesModes = salesModes.filter(
                    (salesMode) => salesMode.name !== "Retrait C&C"
                );
            }

            setMainContentItems(salesModes);
            setIsUserAccountActive(
                // eslint-disable-next-line
                (project.template.pages.ways.salesMethods as PageType).header
                    .userAccount!.active
            );
            setUserAccountMessageItems(
                // eslint-disable-next-line
                (project.template.pages.ways.salesMethods as PageType).header
                    .userAccount!.languages
            );

            setIsLogoActive(
                (project.template.pages.ways.salesMethods as PageType).topBanner
                    .logo.active
            );

            setIsTopBannerActive(
                (project.template.pages.ways.salesMethods as PageType).topBanner
                    .active
            );
            setIsHeaderActive(
                (project.template.pages.ways.salesMethods as PageType).header
                    .active
            );

            setIsInformationMessageActive(
                (project.template.pages.ways.salesMethods as PageType).header
                    .informationMessage.active
            );

            setInformationMessageItems(
                (project.template.pages.ways.salesMethods as PageType).header
                    .informationMessage.languages
            );

            setIsActionButtonsActive(
                (project.template.pages.ways.salesMethods as PageType)
                    .actionsButtons.active
            );

            setActionsButtonsItems(
                (
                    (project.template.pages.ways.salesMethods as PageType)
                        .actionsButtons as ActionsButtonsDesignType
                ).items
            );
            setChosenTransition(
                transitions[
                    (project.template.pages.ways.salesMethods as PageType)
                        .animation
                ]
            );
            break;
        }
        case "clickAndCollect": {
            setIsSubStepIconActive(
                // eslint-disable-next-line
                (project.template.pages.subSteps["clickAndCollect"] as PageType)
                    .header.icon!.active
            );
            setIsSubStepInformationMessageActive(
                (project.template.pages.subSteps["clickAndCollect"] as PageType)
                    .header.informationMessage.active
            );
            setSubStepInformationMessageItems(
                (project.template.pages.subSteps["clickAndCollect"] as PageType)
                    .header.informationMessage.languages
            );
            setIsSubStepTopBannerActive(
                (project.template.pages.subSteps["clickAndCollect"] as PageType)
                    .topBanner.active
            );
            setIsSubStepLogoActive(
                (project.template.pages.subSteps["clickAndCollect"] as PageType)
                    .topBanner.logo.active
            );
            setIsSubStepActionButtonsActive(
                (project.template.pages.subSteps["clickAndCollect"] as PageType)
                    .actionsButtons.active
            );
            setSubStepActionButtons(
                (project.template.pages.subSteps["clickAndCollect"] as PageType)
                    .actionsButtons.items
            );

            setSubStepIconId(
                // eslint-disable-next-line
                (project.template.pages.subSteps["clickAndCollect"] as PageType)
                    .header.icon!.id
            );
            setChosenTransition(
                transitions[
                    (
                        project.template.pages.subSteps[
                            "clickAndCollect"
                        ] as PageType
                    ).animation
                ]
            );
            break;
        }
        case "meansOfPayment": {
            let activeMeansOfPayment = (
                project.template.content
                    .meansOfPayment as ProjectContentItemType
            ).items?.filter(
                (item) =>
                    item.active === true &&
                    item.languages[customerLanguage.name].content !== ""
            );
            if (
                Object.keys(
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    peripheralShopApp?.monetics as any
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ).length === 0
            ) {
                activeMeansOfPayment = activeMeansOfPayment?.filter(
                    (item) =>
                        item.active === true &&
                        item.shortName !== "creditCard" &&
                        item.languages[customerLanguage.name].content !== ""
                );
            }

            setMainContentItems(activeMeansOfPayment);
            setIsUserAccountActive(
                // eslint-disable-next-line
                (project.template.pages.ways.meansOfPayment as PageType).header
                    .userAccount!.active
            );
            setUserAccountMessageItems(
                // eslint-disable-next-line
                (project.template.pages.ways.meansOfPayment as PageType).header
                    .userAccount!.languages
            );

            setIsLogoActive(
                (project.template.pages.ways.meansOfPayment as PageType)
                    .topBanner.logo.active
            );

            setIsTopBannerActive(
                (project.template.pages.ways.meansOfPayment as PageType)
                    .topBanner.active
            );
            setIsHeaderActive(
                (project.template.pages.ways.meansOfPayment as PageType).header
                    .active
            );

            setIsInformationMessageActive(
                (project.template.pages.ways.meansOfPayment as PageType).header
                    .informationMessage.active
            );

            setInformationMessageItems(
                (project.template.pages.ways.meansOfPayment as PageType).header
                    .informationMessage.languages
            );

            setIsActionButtonsActive(
                (project.template.pages.ways.meansOfPayment as PageType)
                    .actionsButtons.active
            );

            setActionsButtonsItems(
                (
                    (project.template.pages.ways.meansOfPayment as PageType)
                        .actionsButtons as ActionsButtonsDesignType
                ).items
            );

            setChosenTransition(
                transitions[
                    (project.template.pages.ways.meansOfPayment as PageType)
                        .animation
                ]
            );

            setCashUuID(
                // eslint-disable-next-line
                (
                    (
                        project.template.content
                            .meansOfPayment as ProjectContentItemType
                    ).items as ProjectMainContentItemType[]
                )
                    // eslint-disable-next-line
                    .find((item) => item.shortName! === "changeMachine")?.id!
            );

            setCreditCardUuId(
                // eslint-disable-next-line
                (
                    (
                        project.template.content
                            .meansOfPayment as ProjectContentItemType
                    ).items as ProjectMainContentItemType[]
                )
                    // eslint-disable-next-line
                    .find((item) => item.shortName! === "creditCard")?.id!
            );
            const localLoyaltyUuId = (
                (
                    project.template.content
                        .meansOfPayment as ProjectContentItemType
                ).items as ProjectMainContentItemType[]
            )
                // eslint-disable-next-line
                .find((item) => item.shortName! === "loyalty");
            if (localLoyaltyUuId !== undefined) {
                setLoyaltyUuId(localLoyaltyUuId.id);
            }
            break;
        }
        case "informationModes": {
            const informationModeBySaleMethod = (
                project.template.content.salesMethods as ProjectContentItemType
            ).items.filter(
                (saleMethod) =>
                    saleMethod.name === selectedSaleModeValue &&
                    saleMethod.active &&
                    saleMethod.languages[customerLanguage.name].content !== ""
            )[0];

            const currentSalesModeInformation = (
                informationModeBySaleMethod.informationModes as InformationModesContentType
            ).items;

            const projectInformationModes = (
                project.template.content
                    .informationModes as ProjectContentItemType
            ).items;

            if (currentSalesModeInformation.length > 0) {
                const activeSalesModeInformationModes: ProjectMainContentItemType[] =
                    [];

                currentSalesModeInformation.forEach((id) => {
                    projectInformationModes.forEach((infoMode) => {
                        if (
                            infoMode.id === id &&
                            (infoMode.active as ItemContentType)[
                                selectedSaleModeValue
                            ] === true
                        ) {
                            activeSalesModeInformationModes.push(infoMode);
                        }
                    });
                });
                setMainContentItems(activeSalesModeInformationModes);
            }

            setIsUserAccountActive(
                // eslint-disable-next-line
                (project.template.pages.ways.informationModes as PageType)
                    .header.userAccount!.active
            );
            setUserAccountMessageItems(
                // eslint-disable-next-line
                (project.template.pages.ways.informationModes as PageType)
                    .header.userAccount!.languages
            );

            setIsLogoActive(
                (project.template.pages.ways.informationModes as PageType)
                    .topBanner.logo.active
            );

            setIsTopBannerActive(
                (project.template.pages.ways.informationModes as PageType)
                    .topBanner.active
            );
            setIsHeaderActive(
                (project.template.pages.ways.informationModes as PageType)
                    .header.active
            );

            setIsInformationMessageActive(
                (project.template.pages.ways.informationModes as PageType)
                    .header.informationMessage.active
            );

            setInformationMessageItems(
                (project.template.pages.ways.informationModes as PageType)
                    .header.informationMessage.languages
            );

            setIsActionButtonsActive(
                (project.template.pages.ways.informationModes as PageType)
                    .actionsButtons.active
            );

            setActionsButtonsItems(
                (
                    (project.template.pages.ways.informationModes as PageType)
                        .actionsButtons as ActionsButtonsDesignType
                ).items
            );

            setChosenTransition(
                transitions[
                    (project.template.pages.ways.informationModes as PageType)
                        .animation
                ]
            );
            break;
        }
        case "informationModesConsommation": {
            setIsSubStepIconActive(
                (
                    (
                        project.template.pages.subSteps[
                            selectedInformationMode
                        ] as PageType
                    ).header.icon as IconType
                ).active
            );
            setIsSubStepInformationMessageActive(
                (
                    project.template.pages.subSteps[
                        selectedInformationMode
                    ] as PageType
                ).header.informationMessage.active
            );
            setSubStepInformationMessageItems(
                (
                    project.template.pages.subSteps[
                        selectedInformationMode
                    ] as PageType
                ).header.informationMessage.languages
            );
            setIsSubStepTopBannerActive(
                (
                    project.template.pages.subSteps[
                        selectedInformationMode
                    ] as PageType
                ).topBanner.active
            );
            setIsSubStepLogoActive(
                (
                    project.template.pages.subSteps[
                        selectedInformationMode
                    ] as PageType
                ).topBanner.logo.active
            );
            setIsSubStepActionButtonsActive(
                (
                    project.template.pages.subSteps[
                        selectedInformationMode
                    ] as PageType
                ).actionsButtons.active
            );
            setSubStepActionButtons(
                (
                    project.template.pages.subSteps[
                        selectedInformationMode
                    ] as PageType
                ).actionsButtons.items
            );

            setSubStepIconId(
                (
                    (
                        project.template.pages.subSteps[
                            selectedInformationMode
                        ] as PageType
                    ).header.icon as IconType
                ).id
            );
            setChosenTransition(
                transitions[
                    (
                        project.template.pages.subSteps[
                            selectedInformationMode
                        ] as PageType
                    ).animation
                ]
            );
            break;
        }
        case "connection": {
            setMainContentItems(
                (
                    project.template.content
                        .connection as ProjectContentItemType
                ).items?.filter(
                    (item) =>
                        item.active === true &&
                        item.languages[customerLanguage.name].content !== ""
                )
            );

            setIsLogoActive(
                (project.template.pages.ways.connection as PageType).topBanner
                    .logo.active
            );

            setIsTopBannerActive(
                (project.template.pages.ways.connection as PageType).topBanner
                    .active
            );
            setIsHeaderActive(
                (project.template.pages.ways.connection as PageType).header
                    .active
            );

            setIsInformationMessageActive(
                (project.template.pages.ways.connection as PageType).header
                    .informationMessage.active
            );

            setInformationMessageItems(
                (project.template.pages.ways.connection as PageType).header
                    .informationMessage.languages
            );

            setIsActionButtonsActive(
                (project.template.pages.ways.connection as PageType)
                    .actionsButtons.active
            );

            setActionsButtonsItems(
                (
                    (project.template.pages.ways.connection as PageType)
                        .actionsButtons as ActionsButtonsDesignType
                ).items
            );

            setChosenTransition(
                transitions[
                    (project.template.pages.ways.connection as PageType)
                        .animation
                ]
            );
            break;
        }
        case "authenticationModes": {
            setSelectedAuthMode("");
            setIsUserAuthenticated(false);
            setMainContentItems(
                (
                    project.template.content
                        .authenticationModes as ProjectContentItemType
                ).items?.filter(
                    (item) =>
                        item.active === true &&
                        item.languages[customerLanguage.name].content !== ""
                )
            );

            setIsLogoActive(
                (project.template.pages.ways.authenticationModes as PageType)
                    .topBanner.logo.active
            );

            setIsTopBannerActive(
                (project.template.pages.ways.authenticationModes as PageType)
                    .topBanner.active
            );
            setIsHeaderActive(
                (project.template.pages.ways.authenticationModes as PageType)
                    .header.active
            );

            setIsInformationMessageActive(
                (project.template.pages.ways.authenticationModes as PageType)
                    .header.informationMessage.active
            );

            setInformationMessageItems(
                (project.template.pages.ways.authenticationModes as PageType)
                    .header.informationMessage.languages
            );

            setIsActionButtonsActive(
                (project.template.pages.ways.authenticationModes as PageType)
                    .actionsButtons.active
            );

            setActionsButtonsItems(
                (
                    (
                        project.template.pages.ways
                            .authenticationModes as PageType
                    ).actionsButtons as ActionsButtonsDesignType
                ).items
            );

            setChosenTransition(
                transitions[
                    (
                        project.template.pages.ways
                            .authenticationModes as PageType
                    ).animation
                ]
            );
            break;
        }
        case "loyaltyConsommation": {
            setMainContentItems(
                (
                    project.template.content
                        .loyaltyConsommation as ProjectContentItemType
                ).items?.filter(
                    (item) =>
                        item.active === true &&
                        item.languages[customerLanguage.name].content !== ""
                )
            );

            setIsLogoActive(
                (project.template.pages.ways.loyaltyConsommation as PageType)
                    .topBanner.logo.active
            );

            setIsTopBannerActive(
                (project.template.pages.ways.loyaltyConsommation as PageType)
                    .topBanner.active
            );
            setIsHeaderActive(
                (project.template.pages.ways.loyaltyConsommation as PageType)
                    .header.active
            );

            setIsInformationMessageActive(
                (project.template.pages.ways.loyaltyConsommation as PageType)
                    .header.informationMessage.active
            );

            setInformationMessageItems(
                (project.template.pages.ways.loyaltyConsommation as PageType)
                    .header.informationMessage.languages
            );

            setIsActionButtonsActive(
                (project.template.pages.ways.loyaltyConsommation as PageType)
                    .actionsButtons.active
            );

            setActionsButtonsItems(
                (
                    (
                        project.template.pages.ways
                            .loyaltyConsommation as PageType
                    ).actionsButtons as ActionsButtonsDesignType
                ).items
            );

            setChosenTransition(
                transitions[
                    (
                        project.template.pages.ways
                            .loyaltyConsommation as PageType
                    ).animation
                ]
            );
            break;
        }
    }

    setIsAnimationIn(true);
}
