import { CATEGORY_TYPE, ITEM_TYPE } from "@constants";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { kioskStore } from "@pages/Kiosk/store";

import { ProductDetails } from "./ProductDetails";
import { ProductTitle } from "./ProductTitle";

export function SecondBoxCardProduct({
    product,
    quantity,
    handleAddProductClick,
    opacity,
    isAllergenIconActive,
    setIsAllergenConfirmationDialogOpened,
}) {
    const { isPrm } = useSnapshot(kioskStore);

    const { isProductDescriptionActive } = useSelector(
        (state) => state.settingSlice.productSetting,
        shallowEqual
    );

    const { isCategorySubCategoryTitleActive } = useSelector(
        (state) => state.settingSlice.productSetting,
        shallowEqual
    );

    const isSubCategoryTitleActive =
        isCategorySubCategoryTitleActive &&
        product.type === CATEGORY_TYPE &&
        (product.isRedirect === undefined || product.isRedirect === false)
            ? product?.isNameDisplayed !== undefined
                ? product?.isNameDisplayed
                : true
            : false;

    const isProductTitleVisible =
        product.type === ITEM_TYPE ||
        (product.type === CATEGORY_TYPE && product.isRedirect === true);

    const isTitleActive =
        isSubCategoryTitleActive ||
        (isProductTitleVisible && product.isTitleShow);

    const isPriceShown =
        product.type !== CATEGORY_TYPE ||
        (product.type === CATEGORY_TYPE &&
            product.isRedirect === true &&
            product.price.content > 0);

    const isProductDescriptionShown =
        isProductDescriptionActive || isPriceShown;

    const secondBoxHeight =
        product.type === ITEM_TYPE ||
        (isTitleActive && isProductDescriptionShown)
            ? "100px"
            : isTitleActive || isProductDescriptionShown
            ? "50px"
            : "none";
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                paddingTop: isPrm ? "0" : "unset",

                height: secondBoxHeight,
                borderRadius: "15px",
                background:
                    quantity > 0
                        ? `transparent linear-gradient(180deg, #ffffff00 0%, var(--global-color) 700%) 100% 100% repeat`
                        : "",
                gap: product.showProductIcons ? "unset" : "",
                alignItems: "center",
                justifyContent: "center",
            }}
            onClick={() => {
                !product.outOfStock && opacity !== "0.2"
                    ? isAllergenIconActive === true
                        ? setIsAllergenConfirmationDialogOpened(true)
                        : handleAddProductClick()
                    : handleAddProductClick();
            }}
        >
            <div
                className={`d-flex justify-content-center align-items-center px-1 pt-1 ${
                    isPrm ? "prm-product-title" : "product-title__clz"
                }`}
                style={{ height: "100%" }}
            >
                {quantity !== 0 && product.isRedirect === false ? (
                    <div
                        style={{
                            color: "var(--global-color)",
                        }}
                    >
                        <span
                            style={{
                                font: "normal bold 20px/22px Segoe UI",
                                fontSize: "var(--large-font)",
                            }}
                        >
                            {quantity}×
                        </span>
                    </div>
                ) : null}

                {isTitleActive ? (
                    <ProductTitle
                        quantity={quantity}
                        productName={product.name}
                    />
                ) : null}
            </div>
            {isProductDescriptionShown ? (
                <ProductDetails
                    product={product}
                    isPriceShown={isPriceShown}
                    isProductDescriptionActive={isProductDescriptionActive}
                />
            ) : null}
        </div>
    );
}
SecondBoxCardProduct.propTypes = {
    product: PropTypes.object.isRequired,
    quantity: PropTypes.number,
    handleAddProductClick: PropTypes.func,
    opacity: PropTypes.string,
    isAllergenIconActive: PropTypes.bool,
    setIsAllergenConfirmationDialogOpened: PropTypes.func,
};
