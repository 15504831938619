import {
    BASIC_COMPOSITION,
    BASIC_COMPOSITION_TYPE,
    CATEGORY_TYPE,
    COMPOSITIONS,
    ETK_CESAR,
    IS_NEXT_IN_LIST,
    ISCHEAPEST,
    ISEXPENSIVE,
    ITEM_PRICE_TYPE,
    ITEM_TYPE,
    NO_ThANKS_CARD,
    STEP_PRICE_TYPE,
    STEP_TYPE,
} from "@constants";
import { t } from "i18next";
import { get as getObjectWithPath, isEqual, merge } from "lodash";
import { snapshot } from "valtio";

import {
    getProductTitle,
    refactorAdvancedDisplay,
    refactorStepMessage,
} from "@components/OrderTaking/Helpers";

import { kioskStore } from "@pages/Kiosk/store";

export const minPriceWithoutSpecialPrice = (type, priceType, compositions) => {
    let isStep = false;
    return Object.entries(compositions)
        .sort(([, a], [, b]) => a[priceType] - b[priceType])
        .filter((prod) => !prod[1][type])
        .reduce((r, [key, value], index) => {
            if (index === 0) {
                if (value.withStepPrice) {
                    isStep = true;
                }
            }
            if (isStep && !value.withStepPrice) {
                value.withStepPrice = "true";
                value.priceType = 2;
                isStep = false;
            }
            return {
                ...r,
                [key]: {
                    ...value,
                    price:
                        index === 0 ? Number(value.specialPrice) : value.price,
                    withSpecialPrice: index === 0 ? true : false,
                    withStepPrice: index === 0 ? false : value.withStepPrice,
                    priceType: index === 0 ? 1 : value.priceType,
                },
            };
        }, {});
};
export const minPriceWithStepPrice = (compositions) => {
    const result = Object.entries(compositions)
        .sort(([, a], [, b]) => a["price"] - b["price"])
        .filter((prod) => prod[1].priceType === ITEM_PRICE_TYPE)
        .reduce((r, [key, value], index) => {
            return {
                ...r,
                [key]: {
                    ...value,
                    price:
                        index === 0
                            ? Number(value.priceOfItemInStep)
                            : value.price,

                    withStepPrice: index === 0 ? true : value.withStepPrice,
                    priceType: index === 0 ? STEP_PRICE_TYPE : value.priceType,
                },
            };
        }, {});

    return result;
};

export const maxPriceWithoutSpecialPrice = (type, priceType, compositions) => {
    let isStep = false;
    return Object.entries(compositions)
        .sort(([, a], [, b]) => b[priceType] - a[priceType])
        .filter((prod) => !prod[1][type])
        .reduce((r, [key, value], index) => {
            if (index === 0) {
                if (value.withStepPrice) {
                    isStep = true;
                }
            }
            if (isStep && !value.withStepPrice) {
                value.withStepPrice = "true";
                value.priceType = 2;
                isStep = false;
            }
            return {
                ...r,
                [key]: {
                    ...value,
                    price:
                        index === 0 ? Number(value.specialPrice) : value.price,
                    withSpecialPrice: index === 0 ? true : false,
                    withStepPrice: index === 0 ? false : value.withStepPrice,
                    priceType: index === 0 ? 1 : value.priceType,
                },
            };
        }, {});
};
export const groupWorkflowShopCard = (workflowShopcard) => {
    const result = [];
    Object.entries(workflowShopcard).forEach(([key, value]) => {
        const item = { ...value, name: value.name ? value.name : key };

        const indexExistItem = result.findIndex((elm) => {
            if (
                item.compositions &&
                Object.keys(item.compositions).length > 0
            ) {
                delete item.compositions.date;
            }

            if (elm.compositions && Object.keys(elm.compositions).length > 0) {
                delete elm.compositions.date;
            }

            return (
                item.iuud === elm.iuud &&
                isEqual(item.selectedOptionsUuid, elm.selectedOptionsUuid) &&
                isEqual(
                    JSON.stringify(item.shopCart),
                    JSON.stringify(elm.shopCart)
                ) &&
                isEqual(item.compositions, elm.compositions)
            );
        });

        if (indexExistItem > -1) {
            let ExistItem = result[indexExistItem];

            ExistItem = {
                ...ExistItem,
                price: ExistItem.price + value.price,
                quantity: ExistItem.quantity + value.quantity,
            };

            result[indexExistItem] = ExistItem;
        } else {
            result.push(item);
        }
    });

    return result;
};

export const getItemComposition = (data) => {
    return Object.keys(data).reduce((prev, curr) => {
        if (curr !== BASIC_COMPOSITION_TYPE) {
            let quantity = data[curr].quantity;
            let name = data[curr].name;
            return [...prev, quantity + "x " + name];
        }
        return [...prev];
    }, []);
};
export const orderItemsToDelete = (data) => {
    return Object.entries(data)
        .sort(([, a], [, b]) => b["priceType"] - a["priceType"])
        .reduce((prev, [, value]) => {
            return [...prev, value];
        }, []);
};
export const getStepPrice = (stepComposition) => {
    return Object.keys(stepComposition).reduce((prev, curr) => {
        return prev + stepComposition[curr].price;
    }, 0);
};

export const kioskVisibilityRefactor = (globalVisibility, data) => {
    return Object.keys(data).reduce((prev, iuud) => {
        return {
            ...prev,
            [getRefactoredSalesModes(iuud)]: globalVisibility
                ? data[iuud].includes(1) && data[iuud].includes(0)
                    ? true
                    : false
                : false,
        };
    }, {});
};
export function getRefactoredSalesModes(saleMode) {
    let language = "";
    switch (saleMode) {
        case "0":
            language = "all";
            break;
        case "1":
            language = "En livraison";
            break;
        case "2":
            language = "Emporter";
            break;
        case "3":
            language = "Sur place";
            break;
        case "4":
            language = "Restaurant";
            break;
        case "5":
            language = "Drive";
            break;
        case "6":
            language = "Retrait C&C";
            break;
        default:
            language = "";
    }
    return language;
}

export const generatePath = (path, isproduct) => {
    const data = path.reduce((prev, curr) => {
        if (curr.name === COMPOSITIONS) return [...prev];
        return [
            ...prev,
            curr,
            {
                name: COMPOSITIONS,
                iuud: COMPOSITIONS,
                type: COMPOSITIONS,
                displayTitle: COMPOSITIONS,
            },
        ];
    }, []);
    if (isproduct) {
        data.pop();
    }
    return data;
};

//!!! en cas de modification de produit composé recalcul des nbre choise par chaque etape */
export const getNumberOfChoice = (path, items, orderWorkflowItemSlice) => {
    if (orderWorkflowItemSlice?.item?.shopCart === undefined) return 0;
    const stepPath = generatePath(path, false);
    let newPath = stepPath?.reduce(
        (prev, curr) => (curr?.name ? [...prev, curr.name] : [...prev, curr]),
        []
    );
    let objectToModify = newPath.reduce((prev, key) => {
        return prev[key] || {};
    }, orderWorkflowItemSlice?.item?.shopCart);
    return Object.keys(objectToModify).reduce((prev, curr) => {
        const found = items.find(
            (elm) => elm.iuud === objectToModify[curr].iuud
        );
        return found ? prev + objectToModify[curr].quantity : prev + 0;
    }, 0);
};

export const getPathStep = (workflowData) => {
    let path = [];
    workflowData.forEach((item, index) => {
        let data = {
            name: item.workflow[item.index].uniqueTitle,
            iuud: item.workflow[item.index].iuudproduct
                ? item.workflow[item.index].iuudproduct
                : item.workflow[item.index].uuidStep,
            type: item.workflow[item.index].type,
            displayTitle: item.workflow[item.index].displayTitle,
        };
        if (index === 0) {
            path.push(data);
        } else {
            path.push({
                name: item.workflow[item.index].path[
                    item.workflow[item.index].path.length - 2
                ].name,
                iuud: item.workflow[item.index].iuudproduct
                    ? item.workflow[item.index].iuudproduct
                    : item.workflow[item.index].uuidStep,
                type: item.workflow[item.index].type,
                displayTitle: item.workflow[item.index].displayTitle,
            });
        }
    });
    return path;
};
export const getBasicCompositionStep = (
    path,
    product,
    globalcard,
    customerLanguage
) => {
    const { selectedSaleModeId } = snapshot(kioskStore);
    const iuudmodifer = product.modifier;
    const iuudproduct = product.iuud;
    const iuudMD5 = iuudmodifer;

    const messageStep =
        product.messageBasicComposition.length > 0
            ? refactorStepMessage(
                  product.messageBasicComposition,
                  customerLanguage
              )
            : t("Do you want to remove an ingredient ?");

    let workflowBasic = [];
    //!!! path workflow
    const path_basic_composition = [...path];

    path_basic_composition.push({
        name: BASIC_COMPOSITION_TYPE,
        iuud: BASIC_COMPOSITION_TYPE,
        type: BASIC_COMPOSITION_TYPE,
        displayTitle: t("BASIC COMPOSITION").toUpperCase(),
    });
    let listItems = [];
    Object.entries(globalcard?.items?.[iuudproduct]?.basicComp)
        .sort((a, b) =>
            a[1].rank ? (b[1].rank ? a[1].rank - b[1].rank : -1) : 1
        )
        .forEach(([iuudItem, value], index) => {
            listItems = [
                ...listItems,
                {
                    indexItem: listItems.length,
                    ...globalcard.items?.[iuudItem],
                    type: BASIC_COMPOSITION,
                    displayTitle: getProductTitle(
                        globalcard.items?.[iuudItem].name
                    ),
                    tva: globalcard.items?.[iuudItem].price?.saleModeVatRates[
                        selectedSaleModeId
                    ]
                        ? globalcard.items?.[iuudItem].price?.saleModeVatRates[
                              selectedSaleModeId
                          ]
                        : 0,
                    basicCompositionActive: true,
                    rank: value.rank ? value.rank : index,
                    isRedirect: false,
                    haveComment: false,
                    isObligatory:
                        globalcard?.items?.[iuudproduct].basicComp[iuudItem]
                            ?.isObligatory,
                    path: generatePath(
                        [
                            ...path_basic_composition,
                            {
                                name: globalcard.items?.[iuudItem].title,
                                iuud: iuudItem,
                                type: ITEM_TYPE,
                                displayTitle:
                                    globalcard.items?.[iuudItem].title,
                            },
                        ],
                        true
                    ),
                },
            ];
        });
    const nbrRows = product?.designBasicComposition?.nbrRows
        ? product?.designBasicComposition?.nbrRows
        : 2;
    const nbrColumn = product?.designBasicComposition?.nbrColumn
        ? product?.designBasicComposition?.nbrColumn
        : 2;

    const isAutoDesign =
        product?.designBasicComposition !== undefined
            ? product?.designBasicComposition?.isAutoDesign
            : true;
    workflowBasic = [
        ...workflowBasic,
        {
            title: t("BASIC COMPOSITION"),
            Items: listItems,
            uuidStep: BASIC_COMPOSITION_TYPE,
            image: "./images/iconecompobase.png",
            iuudproduct,
            isRedirect: false,
            type: BASIC_COMPOSITION,
            previousModifier: iuudMD5,
            override: {},
            isActive: false,
            isCompleted: false,
            minNbrOfChoices: 0,
            maxNbrOfChoices: 0,
            NbrOfChoices: 0,
            path: generatePath(path, false),
            design: { nbrRows, nbrColumn, isAutoDesign },
            messageStep,
        },
    ];
    return workflowBasic;
};

export const getlistitems = (
    iuudStep,
    iuudMD5,
    path,
    typeModifier,
    currentStep,
    globalcard,
    selectedSaleModeValue
) => {
    let listItems = [];
    Object.entries(currentStep?.stepItems)
        .sort((a, b) => a[1].rank - b[1].rank)
        .forEach(([iuud]) => {
            if (globalcard.items?.[iuud] !== undefined) {
                /// product is modifier
                const modifier = globalcard.modifier[iuudMD5][typeModifier][
                    iuudStep
                ].items[iuud]
                    ? globalcard.modifier[iuudMD5][typeModifier][iuudStep]
                          .items[iuud]
                    : "";
                //** path item */
                const pathitem = {
                    name: globalcard.items?.[iuud].title,
                    iuud: iuud,
                    type: globalcard.items?.[iuud].isRedirect
                        ? CATEGORY_TYPE
                        : ITEM_TYPE,
                    displayTitle: globalcard.items?.[iuud].title,
                };
                let price = globalcard.items?.[iuud].price;
                let hasStepItemPrice = false;
                const messageBasicComposition = currentStep.stepItems[iuud]
                    .messageBasicComposition
                    ? currentStep.stepItems[iuud].messageBasicComposition
                    : [];

                if (
                    Object.prototype.hasOwnProperty.call(
                        globalcard.modifier[iuudMD5][typeModifier][iuudStep]
                            .items,
                        iuud
                    )
                ) {
                    if (
                        currentStep?.stepItems[iuud]?.itemPrice &&
                        currentStep?.stepItems[iuud]?.itemPrice.isVisible
                    ) {
                        price = {
                            isVisible: true,
                            content: Number.parseFloat(
                                currentStep?.stepItems[iuud]?.itemPrice?.price
                                    .dflt
                            ),
                            advancedPrice:
                                currentStep?.stepItems[iuud]?.itemPrice?.price
                                    .advanced,
                            saleModeVatRates: currentStep?.stepItems[
                                iuud
                            ]?.itemPrice?.price.saleModeVAT.reduce(
                                (prev, curr) => {
                                    return {
                                        ...prev,
                                        [curr.uuid]: curr.value,
                                    };
                                },
                                {}
                            ),
                        };
                        hasStepItemPrice =
                            currentStep?.stepItems[iuud]?.itemPrice
                                .isVisible !== undefined
                                ? currentStep?.stepItems[iuud]?.itemPrice
                                      .isVisible
                                : false;
                    }
                    const itemVisibility =
                        globalcard.cardType !== ETK_CESAR
                            ? currentStep?.stepItems[iuud].visibilityInfo
                            : globalcard.items?.[iuud].stepVisibility
                            ? globalcard.items?.[iuud].stepVisibility
                            : globalcard.items?.[iuud].visibilityInfo;

                    listItems = [
                        ...listItems,
                        {
                            ...globalcard.items?.[iuud],
                            hasStepItemPrice,
                            price,
                            displayTitle: getProductTitle(
                                globalcard.items?.[iuud].name
                            ),
                            hidePrice: !currentStep?.specificOpts.zeroPrice,

                            iuud: iuud,
                            type: ITEM_TYPE,
                            haveComment: currentStep?.haveComment,
                            isStepItem: true,
                            stepRank: currentStep?.rank,
                            // ! to verify kiosk visibility step override
                            // KioskVisibility:
                            //     currentStep?.stepItems[iuud].visibilityInfo,
                            maxNbrOfChoicesInStep:
                                currentStep?.stepItems[iuud].maxChoices,
                            minNbrOfChoicesInStep:
                                currentStep?.stepItems[iuud].minChoices,
                            nbrOfChoicesWithspecialPrice:
                                currentStep?.stepItems[iuud]
                                    .nbrWithspecialPrice,
                            nbrOfChoicesWithStepPrice:
                                currentStep?.stepItems[iuud].nbrWithPrice,
                            // remainNbrOfChoices:
                            //     currentStep?.stepItems[iuud]
                            //         .maxNbrOfChoicesByItems -
                            //     (currentStep?.stepItems[iuud]
                            //         .nbrOfChoicesWithspecialPrice +
                            //         currentStep?.stepItems[iuud]
                            //             .nbrOfChoicesWithItemPriceInStep),
                            color: currentStep?.stepItems[iuud].color,
                            fid:
                                globalcard.cardType === ETK_CESAR
                                    ? globalcard.items?.[iuud].fid
                                    : currentStep?.stepItems[iuud].fid,
                            specialPrice:
                                currentStep?.stepItems[iuud].specialPrice,
                            priceOfItemInStep:
                                currentStep?.stepItems[iuud].priceStep,
                            modifier,
                            StepPriceRule: currentStep.specificOpts.isNext
                                ? IS_NEXT_IN_LIST
                                : currentStep.specificOpts.isCheapest
                                ? ISCHEAPEST
                                : currentStep.specificOpts.isExpensive
                                ? ISEXPENSIVE
                                : ISCHEAPEST,
                            path: generatePath([...path, pathitem], true),
                            haveWorkflow:
                                globalcard.modifier[modifier]?.steps &&
                                Object.keys(
                                    globalcard.modifier[modifier]?.steps
                                ).length > 0
                                    ? true
                                    : false,

                            haveAdditionalSale:
                                globalcard.modifier[modifier]?.add &&
                                Object.keys(globalcard.modifier[modifier]?.add)
                                    .length > 0
                                    ? true
                                    : false,
                            stepTitle:
                                currentStep.codeEcran !== undefined
                                    ? currentStep.codeEcran
                                    : currentStep.title,

                            stepDisplayTitle: getProductTitle(
                                currentStep.name,
                                currentStep.id
                            ),
                            visibilityInfo: itemVisibility,
                            KioskVisibility: kioskVisibilityRefactor(
                                itemVisibility.isVisible,
                                itemVisibility.dflt,
                                iuud
                            ),
                            autoPermutation: currentStep.maxChoices === 1,
                            messageBasicComposition,
                        },
                    ];
                } else {
                    if (
                        currentStep?.stepItems[iuud]?.itemPrice &&
                        currentStep?.stepItems[iuud]?.itemPrice.isVisible
                    ) {
                        price = {
                            isVisible: true,
                            content: Number.parseFloat(
                                currentStep?.stepItems[iuud]?.itemPrice?.price
                                    .default
                            ),
                            advancedPrice:
                                currentStep?.stepItems[iuud]?.itemPrice?.price
                                    .advanced,
                            saleModeVatRates: currentStep?.stepItems[
                                iuud
                            ]?.itemPrice?.price.saleModeVAT.reduce(
                                (prev, curr) => {
                                    return {
                                        ...prev,
                                        [curr.uuid]: curr.value,
                                    };
                                },
                                {}
                            ),
                        };
                        hasStepItemPrice =
                            currentStep?.stepItems[iuud]?.itemPrice
                                .isVisible !== undefined
                                ? currentStep?.stepItems[iuud]?.itemPrice
                                      .isVisible
                                : false;
                    }

                    const itemVisibility =
                        globalcard.cardType !== ETK_CESAR
                            ? currentStep?.stepItems[iuud].visibilityInfo
                            : globalcard.items?.[iuud].stepVisibility
                            ? globalcard.items?.[iuud].stepVisibility
                            : globalcard.items?.[iuud].visibilityInfo;

                    listItems = [
                        ...listItems,
                        {
                            ...globalcard.items?.[iuud],
                            iuud,
                            hasStepItemPrice,
                            price,
                            type: ITEM_TYPE,
                            haveComment: currentStep.haveComment,
                            isStepItem: true,
                            stepRank: currentStep?.rank,
                            fid:
                                globalcard.cardType === ETK_CESAR
                                    ? globalcard.items?.[iuud].fid
                                    : currentStep?.stepItems[iuud].fid,
                            maxNbrOfChoicesInStep:
                                currentStep?.stepItems[iuud].maxChoices,
                            minNbrOfChoicesInStep:
                                currentStep?.stepItems[iuud].minChoices,
                            nbrOfChoicesWithspecialPrice:
                                currentStep?.stepItems[iuud]
                                    .nbrWithspecialPrice,
                            nbrOfChoicesWithStepPrice:
                                currentStep?.stepItems[iuud].nbrWithPrice,
                            // remainNbrOfChoices:
                            //     currentStep?.stepItems[iuud]
                            //         .maxNbrOfChoicesByItems -
                            //     (currentStep?.stepItems[iuud]
                            //         .nbrOfChoicesWithItemPriceInStep +
                            //         currentStep?.stepItems[iuud]
                            //             .nbrOfChoicesWithspecialPrice),
                            color: currentStep?.stepItems[iuud].color,
                            specialPrice:
                                currentStep?.stepItems[iuud].specialPrice,
                            priceOfItemInStep:
                                currentStep?.stepItems[iuud].priceStep,
                            StepPriceRule: currentStep.specificOpts.isNext
                                ? IS_NEXT_IN_LIST
                                : currentStep.specificOpts.isCheapest
                                ? ISCHEAPEST
                                : currentStep.specificOpts.isExpensive
                                ? ISEXPENSIVE
                                : ISCHEAPEST,
                            hidePrice: !currentStep?.specificOpts.zeroPrice,
                            modifier,
                            path: generatePath([...path, pathitem], true),
                            haveWorkflow:
                                globalcard.modifier[modifier]?.steps &&
                                Object.keys(
                                    globalcard.modifier[modifier]?.steps
                                ).length > 0
                                    ? true
                                    : false,

                            haveAdditionalSale:
                                globalcard.modifier[modifier]?.add &&
                                Object.keys(globalcard.modifier[modifier]?.add)
                                    .length > 0
                                    ? true
                                    : false,
                            stepTitle:
                                currentStep.codeEcran !== undefined
                                    ? currentStep.codeEcran
                                    : currentStep.title,
                            stepDisplayTitle: getProductTitle(
                                currentStep.name,
                                currentStep.id
                            ),
                            visibilityInfo: itemVisibility,
                            KioskVisibility: kioskVisibilityRefactor(
                                itemVisibility.isVisible,
                                itemVisibility.dflt,
                                iuud
                            ),
                            autoPermutation: currentStep.maxChoices === 1,
                            messageBasicComposition,
                        },
                    ];
                }
            }
        });

    if (
        currentStep.specificOpts?.nextButton &&
        currentStep.minChoices === 0 &&
        globalcard.modifier[iuudMD5]?.steps !== undefined &&
        Object.keys(globalcard.modifier[iuudMD5]?.steps).length >= 1
    ) {
        listItems.push({
            type: IS_NEXT_IN_LIST,
            visibilityInfo: { isVisible: true },
            KioskVisibility: { [selectedSaleModeValue]: true },
        });
    }

    if (
        currentStep.specificOpts?.noButton &&
        currentStep.minChoices === 0 &&
        globalcard.modifier[iuudMD5]?.steps !== undefined &&
        Object.keys(globalcard.modifier[iuudMD5]?.steps).length >= 1
    ) {
        listItems.push({
            type: NO_ThANKS_CARD,
            visibilityInfo: { isVisible: true },
            KioskVisibility: { [selectedSaleModeValue]: true },
        });
    }

    // list items in step

    return listItems;
};

export const GetWorkflowData = (
    product,
    workflowData,
    globalcard,
    orderWorkflowItemSlice,
    selectedSaleModeValue
) => {
    const iuudproduct = product.iuud;
    const iuudMD5 = product.modifier;
    let workflow = [];
    const { customerLanguage } = snapshot(kioskStore);

    // ** path in workflow
    const pathStep = getPathStep(workflowData);

    // ** get basic_composition *//
    if (product?.haveBasicComposition) {
        const pathBasicComposition = [...pathStep];

        if (pathBasicComposition.length > 0) {
            pathBasicComposition.push({
                name: COMPOSITIONS,
                iuud: COMPOSITIONS,
                type: COMPOSITIONS,
                displayTitle: t("BASIC COMPOSITION").toUpperCase(),
            });

            if (workflowData.length > 0) {
                pathBasicComposition.push({
                    name:
                        product.title +
                        orderWorkflowItemSlice.item.iuudworkflow,
                    iuud: product.iuud,
                    type: product.isRedirect ? CATEGORY_TYPE : ITEM_TYPE,
                    displayTitle: getProductTitle(product.name),
                });
            } else {
                pathBasicComposition.push({
                    name: product.title,
                    iuud: product.iuud,
                    type: product.isRedirect ? CATEGORY_TYPE : ITEM_TYPE,
                    displayTitle: getProductTitle(product.name),
                });
            }
        }

        workflow = getBasicCompositionStep(
            pathBasicComposition,
            product,
            globalcard,
            customerLanguage
        );
    }
    // **   get list of uuid step

    if (iuudMD5 && globalcard.modifier[iuudMD5] && product?.haveWorkflow) {
        Object.entries(globalcard.modifier[iuudMD5]?.steps)
            .sort((a, b) => a[1].rank - b[1].rank)
            .forEach(([iuudStep, value]) => {
                const isOverrideStep =
                    Object.keys(
                        globalcard.modifier[iuudMD5]?.steps[iuudStep].ovr
                    ).length > 0;

                let currentStep = globalcard?.steps?.[iuudStep];

                if (isOverrideStep) {
                    currentStep = merge(
                        {},
                        globalcard?.steps?.[iuudStep],
                        globalcard.modifier[iuudMD5]?.steps[iuudStep].ovr
                    );
                    const overriddenStepItems = Object.keys(
                        currentStep.stepItems
                    ).reduce((prev, curr) => {
                        return {
                            ...prev,
                            [curr]: {
                                ...globalcard?.steps?.[iuudStep].stepItems[
                                    curr
                                ],
                                ...currentStep.stepItems[curr],
                            },
                        };
                    }, {});
                    currentStep.stepItems = overriddenStepItems;
                }

                const messageStep = globalcard.modifier[iuudMD5]?.steps[
                    iuudStep
                ]?.msg
                    ? refactorStepMessage(
                          globalcard.modifier[iuudMD5]?.steps[iuudStep]?.msg,
                          customerLanguage
                      )
                    : "";
                const nbrRows = globalcard.modifier[iuudMD5]?.steps[iuudStep]
                    ?.design?.nbrRows
                    ? globalcard.modifier[iuudMD5]?.steps[iuudStep]?.design
                          ?.nbrRows
                    : 2;
                const nbrColumn = globalcard.modifier[iuudMD5]?.steps[iuudStep]
                    ?.design?.nbrColumn
                    ? globalcard.modifier[iuudMD5]?.steps[iuudStep]?.design
                          ?.nbrColumn
                    : 2;
                const isAutoDesign =
                    globalcard.modifier[iuudMD5]?.steps[iuudStep]?.design !==
                    undefined
                        ? globalcard.modifier[iuudMD5]?.steps[iuudStep]?.design
                              ?.isAutoDesign
                        : true;

                currentStep = {
                    ...currentStep,
                    codeEcran: isOverrideStep
                        ? currentStep.codeEcran
                        : currentStep.title,
                    rank: value.rank + 1,
                    haveComment: currentStep?.isComment
                        ? currentStep.isComment
                        : false,
                };
                let listItems = [];
                //** path step */
                const path = [...pathStep];

                const titleStep = getProductTitle(
                    currentStep?.name,
                    customerLanguage
                );
               

                if (product.modifier && workflowData.length > 0) {
                    path.push({
                        name:
                            product.title +
                            orderWorkflowItemSlice.item.iuudworkflow,
                        iuud: product.iuud,
                        type: product.isRedirect ? CATEGORY_TYPE : ITEM_TYPE,
                        displayTitle: getProductTitle(product.name),
                    });
                } else {
                    path.push({
                        name: currentStep.title,
                        iuud: iuudStep,
                        type: STEP_TYPE,
                        displayTitle: titleStep,
                    });
                }
                // get list uuid items in step
                listItems = getlistitems(
                    iuudStep,
                    iuudMD5,
                    path,
                    STEP_TYPE,
                    currentStep,
                    globalcard,
                    selectedSaleModeValue
                );
                if (!listItems) return;
                workflow = [
                    ...workflow,
                    {
                        title: titleStep,
                        uniqueTitle: currentStep.title,
                        Items: listItems,
                        messageStep,
                        uuidStep: iuudStep,
                        haveComment: currentStep?.haveComment,
                        iuudproduct,
                        type: product.isRedirect ? CATEGORY_TYPE : STEP_TYPE,
                        image: currentStep.img
                            ? currentStep.img
                            : "/images/nopicture.png",

                        previousModifier: iuudMD5,
                        minNbrOfChoices: currentStep.minChoices,
                        maxNbrOfChoices: currentStep.maxChoices,
                        NbrOfChoices: getNumberOfChoice(
                            path,
                            listItems,
                            orderWorkflowItemSlice
                        ),
                        path: generatePath(path, false),
                        nbrOfChoicesWithStepPrice: currentStep.nbrWithPrice,
                        nbrOfChoicesWithspecialPrice:
                            currentStep.nbrWithspecialPrice,
                        selectedChoicesWithspecialPrice: 0,
                        selectedChoicesWithStepPrice: 0,

                        design: { nbrRows, nbrColumn, isAutoDesign },
                        isCreational: currentStep.isCreational,
                        displayTitle: titleStep,
                        specificOption: currentStep.specificOpts,
                        OutOfStock: currentStep.outStock,
                        // remainNbrOfChoices:
                        //     currentStep.maxNbrOfChoices -
                        //     (currentStep.nbrOfChoicesWithspecialPrice +
                        //         currentStep.nbrOfChoicesWithItemPrice),
                    },
                ];
            });
    }
    return workflow;
};
export const getPathWithStateCopy = (state, payload) => {
    const newState = { ...state }; //JSON.parse(JSON.stringify(state));
    const path = payload.path.map((elm) => {
        return elm?.name !== undefined ? elm.name : elm;
    });
    return { newState, path };
};
export const reorderShopCart = (shopCart) => {
    return Object.entries(shopCart)
        .sort((a, b) => a[1].stepRank - b[1].stepRank)
        .reduce((prev, curr) => {
            return { ...prev, [curr[0]]: curr[1] };
        }, {});
};

export const getObjectPrice = (keyName, itemObject) => {
    let menuItemPrice = 0;

    for (var key in itemObject) {
        if (key === keyName) {
            menuItemPrice += itemObject[keyName];
        } else {
            if (
                typeof itemObject[key] === "object" &&
                key !== "workflow" &&
                key !== BASIC_COMPOSITION_TYPE
            ) {
                menuItemPrice += getObjectPrice(keyName, itemObject[key]);
            }
        }
    }

    return menuItemPrice;
};

export const cancelModifyProduct = (path, productBeforeUpdate) => {
    const newpath = path.map((elm) => {
        return elm?.name !== undefined ? elm.name : elm;
    });

    const stepPath = newpath.join(".");

    const res = getObjectWithPath(productBeforeUpdate.shopCart, stepPath);

    return res === undefined ? {} : res;
};

export const refactorPriceQuantityItemsToDelete = (itemToDelete) => {
    const result = [];
    itemToDelete.forEach((item) => {
        const indexExistItem = result.findIndex((elm) => {
            return (
                item.iuud === elm.iuud &&
                isEqual(item.selectedOptionsUuid, elm.selectedOptionsUuid) &&
                isEqual(
                    JSON.stringify(item.shopCart),
                    JSON.stringify(elm.shopCart)
                )
            );
        });
        if (indexExistItem > -1) {
            let ExistItem = result[indexExistItem];

            ExistItem = {
                ...ExistItem,
                price: ExistItem.price + item.price,
                quantity: ExistItem.quantity + item.quantity,
            };
            result[indexExistItem] = ExistItem;
        } else {
            result.push(item);
        }
        return result;
    });

    return result;
};

export function refactorGroupedItems(composition) {
    let result = [];
    const data = {};
    const element = composition[Object.keys(composition)[0]];
    if (
        Object.keys(composition).length > 0 &&
        element.type !== BASIC_COMPOSITION_TYPE
    ) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        Object.entries(composition).forEach(([_, value]) => {
            if (data[value.stepTitle]) {
                data[value.stepTitle] = {
                    ...data[value.stepTitle],
                    compositions: [
                        ...data[value.stepTitle].compositions,
                        value,
                    ],
                };
            } else {
                data[value.stepTitle] = { compositions: [value] };
            }
        });
        if (Object.values(data)[0].compositions.length > 1) {
            Object.values(data)[0].compositions =
                refactorPriceQuantityItemsToDelete(
                    Object.values(data)[0].compositions
                );
        }
        result.push(data);
    } else {
        const element = composition[Object.keys(composition)[0]];

        const stepTitleKey = element.type;
        result = [{ [stepTitleKey]: { compositions: [element] } }];
    }
    return result;
}
export const getItemCompositionLabels = (data) => {
    return Object.keys(data).reduce((prev, curr) => {
        if (curr !== BASIC_COMPOSITION_TYPE) {
            let name = data[curr].displayTitle.toLowerCase();
            return [...prev, name];
        } else if (curr === BASIC_COMPOSITION_TYPE) {
            let tab = [];
            Object.keys(data[curr].compositions).forEach((value) => {
                let name =
                    data[curr].compositions[value].displayTitle.toLowerCase();
                tab.push(name);
            });
            return tab;
        }

        return [...prev];
    }, []);
};
