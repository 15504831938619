/* eslint-disable */
import classNames from "classnames";
import { t } from "i18next";

import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { capitalize } from "@helpers/general";

type FormInputsPropsType = {
    name: string;
    type: string;
    setInputName: React.Dispatch<React.SetStateAction<string>>;
    formik: any;
    keyboard: any;
    setIsPhoneFieledSelection: React.Dispatch<React.SetStateAction<boolean>>;
    inputRef: any;
};

export function FormInputs({
    name,
    type,
    setInputName,
    formik,
    keyboard,
    setIsPhoneFieledSelection,
    inputRef,
}: FormInputsPropsType): JSX.Element {
    return (
        <div className="d-flex flex-column" style={{ width: "100%" }}>
            <FormInputLabel name={name} />
            <FormInputItem
                name={name}
                formik={formik}
                setInputName={setInputName}
                type={type}
                keyboard={keyboard}
                setIsPhoneFieledSelection={setIsPhoneFieledSelection}
                inputRef={inputRef}
            />
            <FormInputErrorMessage formik={formik} name={name} />
        </div>
    );
}

type formInputLabelType = {
    name: string;
};
function FormInputLabel({ name }: formInputLabelType) {
    return (
        <label
            htmlFor={name}
            style={{ fontSize: "30px" }}
            className={classNames("label-form-created-account_clz", {
                required__clz: name === "phoneNumber" || name === "firstName",
            })}
        >
            {capitalize(t(name))}
        </label>
    );
}

type formInputItemType = {
    name: string;
    formik: any;
    setInputName: React.Dispatch<React.SetStateAction<string>>;
    type: string;
    keyboard: any;
    setIsPhoneFieledSelection: React.Dispatch<React.SetStateAction<boolean>>;
    inputRef: any;
};
function FormInputItem({
    name,
    formik,
    setInputName,
    keyboard,
    setIsPhoneFieledSelection,
    inputRef,
}: formInputItemType) {
    function onBlurInput(event: React.ChangeEvent<HTMLInputElement>) {
        keyboard.current.caretPosition = event.target.selectionStart;
    }

    return (
        <React.Fragment>
            {name === "phoneNumber" ? (
                <>
                    <PhoneInput
                        buttonStyle={{
                            width: "10%",
                            borderRadius: "20px 0 0 20px",
                        }}
                        inputStyle={{
                            width: "99%",
                            height: "4vh",
                            border: "2px solid #000000",
                            borderRadius: "20px",
                            fontSize: "40px",
                            marginLeft: "0px",
                            textAlign: "center",
                        }}
                        inputProps={{
                            required: true,
                            autoFocus: true,
                            ref: (input: any) => {
                                inputRef.current[name] = input;
                            },
                        }}
                        specialLabel=""
                        country={"fr"}
                        value={formik.values[name]}
                        onChange={formik.handleChange}
                        onFocus={() => {
                            setInputName(name);
                            setIsPhoneFieledSelection(true);
                        }}
                        disableCountryCode={true}
                        placeholder={""}
                        disableDropdown
                        onlyCountries={["fr"]}
                        onBlur={onBlurInput}
                        autoFormat={false}
                    />
                </>
            ) : (
                <input
                    ref={(element: any) => (inputRef.current[name] = element)}
                    id={name}
                    name={name}
                    className="text-center form-field-display p-2"
                    style={{ fontSize: "40px" }}
                    value={formik.values[name]}
                    onFocus={() => {
                        setInputName(name);
                        setIsPhoneFieledSelection(false);
                    }}
                    onChange={formik.handleChange}
                    onBlur={onBlurInput}
                />
            )}
        </React.Fragment>
    );
}

type formInputErrorMessageType = {
    formik: any;
    name: string;
};
function FormInputErrorMessage({ formik, name }: formInputErrorMessageType) {
    return (
        <React.Fragment>
            {formik.touched[name] && formik.errors[name] ? (
                <div style={{ color: "red" }}>{formik.errors[name]}</div>
            ) : null}
        </React.Fragment>
    );
}
