import {
    CATALOGUE_MODE,
    NO_CATEGORIES_ENABLED_FOR_SALESMODE,
} from "@constants";
import { v4 as uuid } from "uuid";
import { useSnapshot } from "valtio";

import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { setIsTechProblemModalOpened } from "@store";

import { getImageContentById, getSkippedStepNumber } from "@helpers/general";

import { FlexboxGrid } from "@components/common/FlexboxGrid";
import { getDefaultCategory } from "@components/OrderTaking/Helpers";
import {
    memoizedCategories,
    setActiveCategory,
    setDefaultCategory,
} from "@components/OrderTaking/Store/feature";
import { syncStore } from "@components/Synchronization/store";

import {
    setAmountDeposited,
    setCreditCardPaymentChoice,
    setEmailTicket,
    setFinalMessageSelectedInformationMode,
    setFinalMessageSelectedRegulationMode,
    setIsAnotherPaymentModeButtonDisabled,
    setIsAuthenticationAccepted,
    setIsBackButtonDisabled,
    setIsBackWithoutCreditCardPaymentChoice,
    setIsCashTransactionValidated,
    setIsCreateAnAccountSelected,
    setIsCreationVoucherCanceled,
    setIsOrderPaidWithLoyaltyAccountBalance,
    setIsOrderPartialPaidWithLoyaltyAccountBalance,
    setIsPaymentRefused,
    setIsPrintTicket,
    setIsRegulationModesModalOpened,
    setIsSelectedSaleModeWithoutInformationModes,
    setIsSendEmailConfirmed,
    setIsSendMailWithPdfActive,
    setIsTransactionCanceled,
    setIsUserAccountModalOpened,
    setKeyboardInputsValues,
    setLeftToPay,
    setNavigationIndex,
    setPaymentModes,
    setPaymentStepCounter,
    setRegulationModesModalKey,
    setSelectedAuthMode,
    setSelectedInformationMode,
    setSelectedRegulationModeValue,
    setSelectedSaleModeId,
    setSelectedSaleModeOrderTaking,
    setSelectedSaleModeValue,
    setTotalOrderAfterPaidWithLoyaltyAccountBalance,
    kioskStore as store,
} from "@pages/Kiosk/store";

import "./index.css";

type ImageContentPropsType = {
    item: ProjectMainContentItemType;
    activeItemsLength: number;
    setIsTpaValinaModalOpened?: React.Dispatch<React.SetStateAction<boolean>>;
};

export function ImageContent({
    item,
    activeItemsLength,
    setIsTpaValinaModalOpened,
}: ImageContentPropsType): JSX.Element {
    const {
        customerLanguage,
        kioskWays,
        navigationIndex,
        isRegulationModesModalOpened,
        selectedInformationMode,
        selectedRegulationModeValue,
        isAuthenticationAccepted,
        isUserAuthenticated,
        isOrderPaidWithLoyaltyAccountBalance,
        project: {
            template: { content, pages },
        },
        selectedSaleModeValue,
        leftToPayAfterCashMachineValidation,
        user,
        paymentModes,
        loyaltyUuId,
        selectedAuthMode,
        isOrderInsertionRefused,
        isRegulationModeModalButtonClicked,
        isOrderPartialPaidWithLoyaltyAccountBalance,
        totalOrderAfterPaidWithLoyaltyAccountBalance,
        isPrm,
        isClickAndCollect,
        orderTotalPrice,
        clickAndCollectOrderSalesMode,
    } = useSnapshot(store);

    const { peripheralShopApp } = useSnapshot(syncStore);
    const dispatch = useDispatch();
    const globalcategorys = useSelector((state) => memoizedCategories(state));
    // eslint-disable-next-line

    const imageSrc = getImageContentById(item.id);
    const globalCategories = useSelector((state) => memoizedCategories(state));

    const isTpaValinaShowModal = (
        content.payment as PaymentContentItemType
    ).items.filter((item) => item.name === "credit card")[0]?.languages[
        customerLanguage.name
    ].paymentInstructions.TPAValina?.active as boolean;

    async function handleOnClickEvent() {
        switch (kioskWays[navigationIndex].name) {
            case "salesMethods": {
                const isLocalSelectedSaleModeWithoutInformationModes =
                    (
                        content.salesMethods as ProjectContentItemType
                    ).items.filter((option: ProjectMainContentItemType) => {
                        return (
                            option.name ===
                            (isClickAndCollect
                                ? clickAndCollectOrderSalesMode
                                : item.name)
                        );
                    })[0]?.informationModes?.items.length === 0 ||
                    (
                        content.informationModes as ProjectContentItemType
                    ).items.filter((option: ProjectMainContentItemType) => {
                        return (
                            (option.active as ItemContentType)[item.name] ===
                            true
                        );
                    }).length === 0;

                setIsSelectedSaleModeWithoutInformationModes(
                    isLocalSelectedSaleModeWithoutInformationModes
                );

                if (item.name === "Delivery" && isUserAuthenticated === false) {
                    setIsUserAccountModalOpened(true);
                } else {
                    setNavigationIndex(
                        navigationIndex +
                            Number(
                                await getSkippedStepNumber(
                                    kioskWays,
                                    navigationIndex,
                                    isAuthenticationAccepted,
                                    isUserAuthenticated,
                                    item.name as string,
                                    selectedInformationMode,
                                    isOrderPaidWithLoyaltyAccountBalance,
                                    selectedRegulationModeValue,
                                    content,
                                    Number(orderTotalPrice),
                                    ""
                                )
                            )
                    );
                }
                console.log(
                    `Selected sale mode value: ${item.name} and setting key: ${item.settingKey}`
                );
                setSelectedSaleModeValue(item.name);
                setSelectedSaleModeOrderTaking(item.settingKey);
                setSelectedSaleModeId(item.id);
                const listVisibleCategories = globalcategorys.filter(
                    // eslint-disable-next-line
                    (category: any) =>
                        category?.visibilityInfo.isVisible &&
                        (category?.KioskVisibility[item.settingKey] ||
                            category?.KioskVisibility[item.settingKey] ===
                                undefined)
                );
                if (listVisibleCategories.length === 0) {
                    setIsTechProblemModalOpened(
                        true,
                        NO_CATEGORIES_ENABLED_FOR_SALESMODE
                    );
                    setNavigationIndex(0);
                    return;
                }
                dispatch(setActiveCategory(listVisibleCategories[0].iuud));

                const defaultCategorie = getDefaultCategory(
                    item.settingKey,
                    globalCategories
                );
                dispatch(setDefaultCategory(defaultCategorie));
                break;
            }
            case "authenticationModes": {
                setNavigationIndex(
                    navigationIndex +
                        Number(
                            await getSkippedStepNumber(
                                kioskWays,
                                navigationIndex,
                                isAuthenticationAccepted,
                                isUserAuthenticated,
                                selectedSaleModeValue,
                                selectedInformationMode,
                                isOrderPaidWithLoyaltyAccountBalance,
                                selectedRegulationModeValue,
                                content,
                                Number(orderTotalPrice),
                                item.shortName as string
                            )
                        )
                );
                console.log(`Selected Authentication Mode: ${item.shortName}`);
                setSelectedAuthMode(item.shortName as string);

                break;
            }
            case "informationModes": {
                setNavigationIndex(
                    navigationIndex +
                        Number(
                            await getSkippedStepNumber(
                                kioskWays,
                                navigationIndex,
                                isAuthenticationAccepted,
                                isUserAuthenticated,
                                selectedSaleModeValue,
                                item.shortName as string,
                                isOrderPaidWithLoyaltyAccountBalance,
                                selectedRegulationModeValue,
                                content,
                                Number(orderTotalPrice)
                            )
                        )
                );
                console.log(`Selected Information Mode: ${item.shortName}`);
                setSelectedInformationMode(item.shortName as string);
                setFinalMessageSelectedInformationMode(
                    item.prettyName as string
                );

                break;
            }
            case "meansOfPayment": {
                if (process.env.REACT_APP_MODE === CATALOGUE_MODE) {
                    setSelectedRegulationModeValue("atTheCounter");
                    setFinalMessageSelectedRegulationMode("At the counter");

                    setNavigationIndex(
                        navigationIndex +
                            Number(
                                await getSkippedStepNumber(
                                    kioskWays,
                                    navigationIndex,
                                    isAuthenticationAccepted,
                                    isUserAuthenticated,
                                    selectedSaleModeValue,
                                    selectedInformationMode,
                                    isOrderPaidWithLoyaltyAccountBalance,
                                    "atTheCounter",
                                    content,
                                    Number(orderTotalPrice)
                                )
                            )
                    );
                } else {
                    console.log(
                        `Selected regulation mode value: ${item.shortName}`
                    );
                    setSelectedRegulationModeValue(item.shortName as string);
                    setFinalMessageSelectedRegulationMode(item.name);

                    if (isOrderInsertionRefused) {
                        setAmountDeposited(0);
                        setLeftToPay(orderTotalPrice);
                        if (
                            ["atTheCounter", "BDP"].includes(
                                item.shortName as string
                            )
                        ) {
                            setNavigationIndex(kioskWays.length - 1);
                        } else {
                            const paymentStepIndex = kioskWays.indexOf(
                                kioskWays.filter(
                                    (step) => step.name === "payment"
                                )[0]
                            );

                            if (paymentStepIndex !== -1) {
                                setNavigationIndex(paymentStepIndex);
                            }
                        }
                    } else {
                        setNavigationIndex(
                            navigationIndex +
                                Number(
                                    await getSkippedStepNumber(
                                        kioskWays,
                                        navigationIndex,
                                        isAuthenticationAccepted,
                                        isUserAuthenticated,
                                        selectedSaleModeValue,
                                        selectedInformationMode,
                                        isOrderPaidWithLoyaltyAccountBalance,
                                        item.shortName as string,
                                        content,
                                        Number(orderTotalPrice)
                                    )
                                )
                        );
                    }
                }

                break;
            }
            case "loyaltyConsommation": {
                const loyaltyPaymentMode = paymentModes.find(
                    (mode) => mode.paymentMode === "loyalty"
                );
                const changeMachinePaymentMode = paymentModes.find(
                    (mode) => mode.paymentMode === "changeMachine"
                );

                if (item.shortName === "useMyBalance") {
                    console.log(`I use my loyalty card balance`);

                    const localLeftToPay =
                        orderTotalPrice -
                        (changeMachinePaymentMode !== undefined
                            ? // eslint-disable-next-line
                              changeMachinePaymentMode!.paymentAmount
                            : 0);
                    console.log({ localLeftToPay });

                    if (localLeftToPay < user.loyaltyAmount) {
                        console.log(`Total payment with loyalty balance`);
                        setIsOrderPaidWithLoyaltyAccountBalance(true);
                        setTotalOrderAfterPaidWithLoyaltyAccountBalance(0);

                        setPaymentModes([
                            ...paymentModes,
                            {
                                paymentModeUuiD: loyaltyUuId,
                                paymentMode: "loyalty",
                                paymentAmount: Number(localLeftToPay),
                                data: {
                                    renderAmount: 0,
                                },
                                totalAmountDeposited: 0,
                            },
                        ]);
                    } else {
                        console.log(`Partial payment with loyalty balance`);
                        setIsOrderPartialPaidWithLoyaltyAccountBalance(true);
                        setTotalOrderAfterPaidWithLoyaltyAccountBalance(
                            Number(
                                (orderTotalPrice - user.loyaltyAmount).toFixed(
                                    2
                                )
                            )
                        );
                        const localPaymentMode = paymentModes.filter(
                            (mode) => mode.paymentMode !== "loyalty"
                        );
                        setPaymentModes([
                            ...localPaymentMode,
                            {
                                paymentModeUuiD: loyaltyUuId,
                                paymentMode: "loyalty",
                                paymentAmount: Number(user.loyaltyAmount),
                                data: {
                                    renderAmount: 0,
                                },
                                totalAmountDeposited: 0,
                            },
                        ]);
                    }
                } else {
                    setIsOrderPartialPaidWithLoyaltyAccountBalance(false);
                    if (loyaltyPaymentMode !== undefined) {
                        const localPaymentMode = paymentModes.filter(
                            (mode) => mode.paymentMode !== "loyalty"
                        );
                        setPaymentModes(localPaymentMode);
                    }
                }

                setAmountDeposited(0);
                setIsCreationVoucherCanceled(false);
                setIsCashTransactionValidated(false);
                setNavigationIndex(
                    navigationIndex +
                        Number(
                            await getSkippedStepNumber(
                                kioskWays,
                                navigationIndex,
                                isAuthenticationAccepted,
                                isUserAuthenticated,
                                selectedSaleModeValue,
                                selectedInformationMode,
                                orderTotalPrice < user.loyaltyAmount &&
                                    item.shortName === "useMyBalance",
                                selectedRegulationModeValue,
                                content,
                                Number(orderTotalPrice)
                            )
                        )
                );

                break;
            }
            case "payment": {
                if (isRegulationModesModalOpened) {
                    if (
                        ["atTheCounter", "BDP"].includes(
                            item.shortName as string
                        )
                    ) {
                        console.log(
                            "Choosing atTheCounter or BDP payment mode in regulation modes modal payment step"
                        );
                        setNavigationIndex(
                            navigationIndex +
                                Number(
                                    await getSkippedStepNumber(
                                        kioskWays,
                                        navigationIndex,
                                        isAuthenticationAccepted,
                                        isUserAuthenticated,
                                        selectedSaleModeValue,
                                        selectedInformationMode,
                                        isOrderPaidWithLoyaltyAccountBalance,
                                        item.shortName as string,
                                        content,
                                        Number(orderTotalPrice)
                                    )
                                )
                        );
                    } else if (item.shortName === "creditCard") {
                        const { mark } = Object.values(
                            // eslint-disable-next-line
                            peripheralShopApp?.monetics as any
                            // eslint-disable-next-line
                        )[0] as any;
                        console.log(
                            `Choosing ${mark} Credit Card payment mode in regulation modes modal payment step`
                        );
                        setIsRegulationModesModalOpened(false);
                        setIsBackWithoutCreditCardPaymentChoice(true);
                        setIsAnotherPaymentModeButtonDisabled(true);
                        setIsBackButtonDisabled(true);
                        setAmountDeposited(0);
                        if (isTpaValinaShowModal && mark === "Valina") {
                            setCreditCardPaymentChoice("");
                            setIsTpaValinaModalOpened?.(true);
                        }
                    } else if (item.shortName === "changeMachine") {
                        console.log(
                            "Choosing changeMachine payment mode in regulation modes modal payment step"
                        );
                        setAmountDeposited(0);
                        setIsCreationVoucherCanceled(false);
                        setIsAnotherPaymentModeButtonDisabled(true);
                        setIsBackButtonDisabled(false);
                        setIsCashTransactionValidated(false);
                        setPaymentStepCounter(0);
                    }
                    setIsRegulationModesModalOpened(false);
                    setSelectedRegulationModeValue(item.shortName as string);
                    setRegulationModesModalKey(uuid());
                    setLeftToPay(
                        leftToPayAfterCashMachineValidation !== 0
                            ? leftToPayAfterCashMachineValidation
                            : isOrderPartialPaidWithLoyaltyAccountBalance
                            ? totalOrderAfterPaidWithLoyaltyAccountBalance
                            : orderTotalPrice
                    );
                    setIsPaymentRefused(false);
                    setIsCashTransactionValidated(false);
                    setIsTransactionCanceled(false);
                    setFinalMessageSelectedRegulationMode(item.name);
                }

                break;
            }
            case "connection": {
                let localIsUserAuthenticationAccepted = false;
                if (item.shortName === "accountFidelite") {
                    setIsCreateAnAccountSelected(true);
                    setIsAuthenticationAccepted(false);
                    setKeyboardInputsValues({});
                } else {
                    if (item.shortName === "iIdentifyMySelf") {
                        setIsAuthenticationAccepted(true);
                        localIsUserAuthenticationAccepted = true;
                    } else {
                        setIsAuthenticationAccepted(false);
                    }
                    setNavigationIndex(
                        navigationIndex +
                            Number(
                                await getSkippedStepNumber(
                                    kioskWays,
                                    navigationIndex,
                                    localIsUserAuthenticationAccepted,
                                    isUserAuthenticated,
                                    selectedSaleModeValue,
                                    selectedInformationMode,
                                    isOrderPaidWithLoyaltyAccountBalance,
                                    selectedRegulationModeValue,
                                    content,
                                    Number(orderTotalPrice),
                                    selectedAuthMode
                                )
                            )
                    );
                }
                break;
            }
            case "printerOptions": {
                if (item.shortName === "envoyer") {
                    const isConfirmationEmailActive = (
                        pages.ways.printerOptions as PrinterOptionsPageType
                    ).printerOptionsConfig.isConfirmationEmailActive;
                    const isUnauthenticatedEmailActive = (
                        pages.ways.printerOptions as PrinterOptionsPageType
                    ).printerOptionsConfig.isUnauthenticatedEmailActive;
                    console.log("printer options: send an email ", {
                        isConfirmationEmailActive,
                        isUserAuthenticated,
                    });

                    if (isConfirmationEmailActive && isUserAuthenticated) {
                        setIsSendEmailConfirmed(true);
                    } else if (
                        !isConfirmationEmailActive &&
                        isUserAuthenticated
                    ) {
                        setEmailTicket(user.email);
                        setNavigationIndex(
                            navigationIndex +
                                Number(
                                    await getSkippedStepNumber(
                                        kioskWays,
                                        navigationIndex,
                                        isAuthenticationAccepted,
                                        isUserAuthenticated,
                                        selectedSaleModeValue,
                                        selectedInformationMode,
                                        isOrderPaidWithLoyaltyAccountBalance,
                                        selectedRegulationModeValue,
                                        content,
                                        Number(orderTotalPrice),
                                        selectedAuthMode
                                    )
                                )
                        );
                    } else if (
                        isUnauthenticatedEmailActive &&
                        !isUserAuthenticated
                    ) {
                        setIsSendEmailConfirmed(true);
                    } else if (
                        !isUnauthenticatedEmailActive &&
                        !isUserAuthenticated
                    ) {
                        setNavigationIndex(
                            navigationIndex +
                                Number(
                                    await getSkippedStepNumber(
                                        kioskWays,
                                        navigationIndex,
                                        isAuthenticationAccepted,
                                        isUserAuthenticated,
                                        selectedSaleModeValue,
                                        selectedInformationMode,
                                        isOrderPaidWithLoyaltyAccountBalance,
                                        selectedRegulationModeValue,
                                        content,
                                        Number(orderTotalPrice),
                                        selectedAuthMode
                                    )
                                )
                        );
                    }
                    setIsPrintTicket(false);
                    setIsSendMailWithPdfActive(true);
                } else {
                    if (item.shortName === "oui") {
                        console.log("printer options: YES print the ticket");
                        setIsPrintTicket(true);
                    } else if (item.shortName === "non") {
                        console.log("printer options: NO print the ticket");
                        setIsPrintTicket(false);
                    } else if (item.shortName === "nepasenvoyer") {
                        console.log(
                            "printer options: No Need to print the ticket"
                        );
                        setIsSendMailWithPdfActive(false);
                        setIsSendEmailConfirmed(false);
                        setEmailTicket("");
                        setIsPrintTicket(false);
                    }
                    setNavigationIndex(
                        navigationIndex +
                            Number(
                                await getSkippedStepNumber(
                                    kioskWays,
                                    navigationIndex,
                                    isAuthenticationAccepted,
                                    isUserAuthenticated,
                                    selectedSaleModeValue,
                                    selectedInformationMode,
                                    isOrderPaidWithLoyaltyAccountBalance,
                                    selectedRegulationModeValue,
                                    content,
                                    Number(orderTotalPrice),
                                    selectedAuthMode
                                )
                            )
                    );
                }

                break;
            }
            default: {
                setNavigationIndex(navigationIndex + 1);

                break;
            }
        }
    }

    const isImageDisabled =
        kioskWays[navigationIndex].name === "payment" &&
        isRegulationModesModalOpened &&
        isRegulationModeModalButtonClicked;

    return isPrm ? (
        <div
            className="d-flex flex-column align-items-center justify-content-center"
            onClick={() => {
                if (isImageDisabled === false) {
                    handleOnClickEvent();
                }
            }}
            style={{
                pointerEvents: isImageDisabled ? "none" : "auto",
                opacity: isImageDisabled ? "0.5" : 1,
                width: "100%",
            }}
        >
            <div
                className="d-flex justify-content-center align-items-center"
                style={{
                    position: "relative",
                }}
            >
                <img
                    src={imageSrc}
                    alt={item.name}
                    style={{
                        height: "210px",
                        width: "210px",
                        objectFit: "fill",
                    }}
                />
            </div>

            {item.languages[customerLanguage.name]?.isTextVisible ? (
                <span
                    className="preview-text text-center"
                    style={{ fontSize: "20px" }}
                >
                    {item["languages"][customerLanguage.name].name}
                </span>
            ) : null}
        </div>
    ) : (
        <div
            className="d-flex flex-column justify-content-center align-items-center col my-4"
            onClick={() => {
                if (isImageDisabled === false) {
                    handleOnClickEvent();
                }
            }}
            style={{
                gridTemplateRows: "6fr 1fr",
                pointerEvents: isImageDisabled ? "none" : "auto",
                opacity: isImageDisabled ? "0.5" : 1,
            }}
        >
            <FlexboxGrid
                justifyContentCentered={true}
                className="align-items-end h-100"
            >
                <img
                    src={imageSrc}
                    alt={item.name}
                    style={{
                        width: "330px",
                        height: "330px",
                    }}
                />
            </FlexboxGrid>

            <div
                className="d-flex justify-content-center align-items-center"
                style={{ width: activeItemsLength >= 3 ? "50%" : "" }}
            >
                {item.languages[customerLanguage.name]?.isTextVisible ===
                true ? (
                    <span
                        className="two-lines-preview-text text-center"
                        style={{ fontSize: "35px", minHeight: "100px" }}
                    >
                        {item["languages"][customerLanguage.name].name}
                    </span>
                ) : null}
            </div>
        </div>
    );
}
