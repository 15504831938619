import React from "react";
import { useTranslation } from "react-i18next";

import "./index.css";

export default function NetworkErrorCashMachine(): JSX.Element {
    const { t } = useTranslation();
    return (
        <div className="network-error-container">
            <div className="d-flex justify-content-center align-items-center w-100">
                <span className="network-error-title">
                    {t("Connection lost with the cash machine")}
                </span>
            </div>
            <div className="d-flex justify-content-center align-items-center text-center  w-100">
                <span className="network-error-message">
                    {t(
                        "Due to a problem with the cash machine, if you have inserted money, please contact the manager"
                    )}
                </span>
            </div>
        </div>
    );
}
