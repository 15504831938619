import { ETK_CESAR, ITEM_TYPE } from "@constants";

import { useSelector } from "react-redux";

import { getItemPriceWithOption, getPrice } from "../../../../Helpers";
import { defaultOptionProduct } from "./defaultOptionProduct";

function getItemPrice(currentWorkflow, product, selectedSaleModeId, itemMenu) {
    const { cardType } = useSelector((state) => state.orderSlice);
    if (
        product.type !== ITEM_TYPE &&
        (product.isRedirect === undefined || product.isRedirect === false)
    ) {
        return {
            price: 0,
            tva: 0,
        };
    }
    const defaultItemOptions =
        (!product.isStepItem && Object.keys(product.opt).length > 0) ||
        (product.isStepItem && Object.keys(product.opt).length > 0)
            ? defaultOptionProduct(product.optionsKiosk.Options)
            : [];

    const { specialPrice, priceOfItemInStep, hasStepItemPrice } = product;
    let itemPrice = getPrice(
        [selectedSaleModeId, "KIOSK", ...defaultItemOptions],
        product.price,
        defaultItemOptions.length === 0
    );

    // ! in progress waiting for rules

    if (currentWorkflow && product.isStepItem) {
        const {
            nbrOfChoicesWithspecialPrice, //nbr de click max  gr1 ---->0
            selectedChoicesWithspecialPrice, //nbr de select when  on click in gr1 --->0
            nbrOfChoicesWithStepPrice, // nbr de click max  de gr2   comme  0  et 6 ----->0
            selectedChoicesWithStepPrice, // nbre de click in  gr2 when etape 6 ----->0
            maxNbrOfChoices,
            NbrOfChoices, //nbr de select when on click general gr1/gr2
        } = currentWorkflow.workflow[currentWorkflow.index];
        if (cardType === ETK_CESAR) {
            // ?=> control prix group 1 et 2
            if (
                selectedChoicesWithspecialPrice <
                    nbrOfChoicesWithspecialPrice ||
                nbrOfChoicesWithspecialPrice === maxNbrOfChoices
            ) {
                //! gr1
                itemPrice.price = parseFloat(specialPrice);
            } else {
                //!gr2
                if (hasStepItemPrice === false) {
                    // prix non avancé
                    itemPrice.price = parseFloat(priceOfItemInStep);
                }

                if (nbrOfChoicesWithStepPrice > 0) {
                    if (
                        nbrOfChoicesWithStepPrice > selectedChoicesWithStepPrice
                    ) {
                        if (hasStepItemPrice === false) {
                            itemPrice.price = parseFloat(priceOfItemInStep);
                        }
                    }
                } else if (
                    itemMenu?.selectedOptions.length > 0 &&
                    Object.keys(product.opt).length > 0
                ) {
                    itemPrice = getItemPriceWithOption(
                        itemMenu?.optionIndex,
                        product.optionsKiosk.Options,
                        product.price
                    );
                }
            }
        } else {
            if (
                selectedChoicesWithspecialPrice < nbrOfChoicesWithspecialPrice //! gr1
            ) {
                itemPrice.price = parseFloat(specialPrice);
            } else if (
                nbrOfChoicesWithStepPrice === 0 ||
                (nbrOfChoicesWithStepPrice > 0 &&
                    NbrOfChoices <
                        nbrOfChoicesWithspecialPrice +
                            nbrOfChoicesWithStepPrice) //! gr2
            ) {
                if (hasStepItemPrice === false) {
                    itemPrice.price = parseFloat(priceOfItemInStep);
                }
                if (itemMenu?.selectedOptionsUuid.length > 0) {
                    //! principal product with options

                    if (
                        Object.keys(product.opt).length > 0 &&
                        !product?.optionsKiosk?.showOptions
                    ) {
                        itemPrice = getItemPriceWithOption(
                            itemMenu?.optionIndex,
                            product.optionsKiosk.Options,
                            product.price
                        );
                    }
                }
            } else if (
                NbrOfChoices >=
                    nbrOfChoicesWithspecialPrice + nbrOfChoicesWithStepPrice &&
                NbrOfChoices <= maxNbrOfChoices //! rest cliques
            ) {
                if (itemMenu?.selectedOptionsUuid.length === 0) {
                    //! principal product without options
                    if (hasStepItemPrice === false) {
                        itemPrice.price = parseFloat(priceOfItemInStep);
                    }

                    if (
                        Object.keys(product.opt).length > 0 &&
                        !product?.optionsKiosk?.showOptions //! principal product without option but product with options
                    ) {
                        itemPrice = {
                            price: 0,
                            tva: 0,
                        };
                    }
                } else if (itemMenu?.selectedOptionsUuid.length > 0) {
                    //! principal product with options

                    if (
                        Object.keys(product.opt).length > 0 &&
                        !product?.optionsKiosk?.showOptions
                    ) {
                        itemPrice = getItemPriceWithOption(
                            itemMenu?.optionIndex,
                            product.optionsKiosk.Options,
                            product.price
                        );
                    }
                }
            }
        }
    }
    return {
        price: itemPrice.price,
        tva: product.price?.saleModeVatRates[selectedSaleModeId]
            ? product.price?.saleModeVatRates[selectedSaleModeId]
            : 0,
    };
}

export default getItemPrice;
