import { CheckIcon } from "@aureskonnect/react-ui";
import {
    CLICK_AND_COLLECT_ITEM_NAME,
    GODIA_LOYALTY,
    SALES_METHODS_STEP_NAME,
    SKIPPED_PAGES,
} from "@constants";
import classnames from "classnames";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import { IoMdStar } from "react-icons/io";
import { Card } from "reactstrap";

import {
    addItemInList,
    getImageContentById,
    removeItemFromList,
} from "@helpers/general";

import { ScrollContainer } from "@components/ScrollContainer";
import { syncStore } from "@components/Synchronization/store";

import {
    kioskStore,
    setIsSkippedStep,
    setLocalProjectItems,
    setPagesItems,
} from "@pages/Kiosk/store";

import "./index.css";
import { ReactSwitchWrapper } from "./ReactSwitchWrapper";

type MainContentPropsType = {
    items:
        | ProjectMainContentItemType[]
        | LanguagesItemType[]
        | OptionsItemType[];
    contentName: string;
};

export function MainContentWrapper({
    items,
    contentName,
}: MainContentPropsType): JSX.Element {
    const {
        activeTabTitle,
        pagesItems,
        localProject: { template },
    } = useSnapshot(kioskStore);
    const { peripheralShopApp, selectedShopApp } = useSnapshot(syncStore);
    const isSwitchShown =
        SKIPPED_PAGES.includes(contentName) &&
        (items as ProjectMainContentItemType[]).filter(
            (item: ProjectMainContentItemType) => item.active
        ).length === 1;

    const isCustomerAccountActive = (
        (template.content.options as OptionsType).items as OptionsItemType[]
    )[0].active;

    const selectedSaleMethodDeliveryExist = (
        template.content.salesMethods as ProjectContentItemType
    ).items.filter(
        (sale: ProjectMainContentItemType) => sale.name === "Delivery"
    )[0];

    if (
        contentName === "meansOfPayment" &&
        // eslint-disable-next-line
        Object.keys(peripheralShopApp?.monetics as any).length === 0
    ) {
        items = (items as ProjectMainContentItemType[])?.filter(
            (item) => item.shortName !== "creditCard"
        );
    }

    function handleOnClickCheckIconEvent(
        item: ProjectMainContentItemType | LanguagesItemType | OptionsItemType
    ) {
        const array = [...items];

        const activeItemsCount = array.filter(
            (
                item:
                    | ProjectMainContentItemType
                    | LanguagesItemType
                    | OptionsItemType
            ): boolean => item.active === true
        ).length;

        const element = array.find((element) => {
            return element.id === item.id;
        });
        const itemIndex = array.indexOf(
            element as
                | ProjectMainContentItemType
                | LanguagesItemType
                | OptionsItemType
        );

        if (element !== undefined) {
            array[itemIndex] = {
                ...array[itemIndex],
                active:
                    contentName === "options"
                        ? !item.active
                        : (item as LanguagesItemType).isDefault === true
                        ? true
                        : contentName === SALES_METHODS_STEP_NAME &&
                          (
                              template.content[
                                  contentName
                              ] as ProjectContentItemType
                          ).items.filter((saleMethod) => {
                              return (
                                  saleMethod.name ===
                                      CLICK_AND_COLLECT_ITEM_NAME &&
                                  saleMethod.active
                              );
                          })[0]?.name === CLICK_AND_COLLECT_ITEM_NAME &&
                          activeItemsCount === 2 &&
                          JSON.parse(JSON.stringify(item)).name !==
                              CLICK_AND_COLLECT_ITEM_NAME
                        ? true
                        : activeItemsCount !== 1
                        ? !item.active
                        : true,
            };

            if (
                (array[itemIndex] as OptionsItemType).title ===
                "Customer account"
            ) {
                if (!isCustomerAccountActive === false) {
                    const localPagesItems: ItemsPagesType = { ...pagesItems };
                    const authenticationModesIndex = Object.keys(
                        localPagesItems.ways
                    ).includes("authenticationModes")
                        ? Object.keys(localPagesItems.ways).indexOf(
                              "authenticationModes"
                          )
                        : -1;

                    const connectionIndex = Object.keys(
                        localPagesItems.ways
                    ).includes("connection")
                        ? Object.keys(localPagesItems.ways).indexOf(
                              "connection"
                          )
                        : -1;

                    let loyaltyConsommationIndex = Object.keys(
                        localPagesItems.ways
                    ).includes("loyaltyConsommation")
                        ? Object.keys(localPagesItems.ways).indexOf(
                              "loyaltyConsommation"
                          )
                        : -1;

                    if (
                        localPagesItems.ways.authenticationModes !==
                            undefined ||
                        localPagesItems.ways.connection !== undefined ||
                        localPagesItems.ways.loyaltyConsommation !== undefined
                    ) {
                        const newLocalPageItem: ItemsPagesType = {
                            ...localPagesItems,
                        };

                        if (authenticationModesIndex !== -1) {
                            const [removedElement, newSourceList] =
                                removeItemFromList(
                                    Object.values(newLocalPageItem["ways"]),
                                    authenticationModesIndex
                                );

                            const removedItem = { ...removedElement };

                            removedItem.prefix = "elements";

                            newLocalPageItem["ways"] = Object.assign(
                                {},
                                ...newSourceList.map((element) => ({
                                    [element.name]: { ...element },
                                }))
                            );

                            const destinationList = Object.values(
                                newLocalPageItem["elements"]
                            );

                            const newDestinationList = addItemInList(
                                destinationList,
                                authenticationModesIndex,
                                removedItem
                            ) as PageType[];

                            newLocalPageItem["elements"] = Object.assign(
                                {},
                                ...newDestinationList.map((element) => ({
                                    [element.name]: {
                                        ...element,
                                    },
                                }))
                            );
                        }

                        const data: ItemsPagesType = { ...newLocalPageItem };

                        if (connectionIndex !== -1) {
                            const [removedElement1, newSourceList1] =
                                removeItemFromList(
                                    Object.values(data["ways"]),
                                    connectionIndex
                                );

                            const removedItem1 = { ...removedElement1 };

                            removedItem1.prefix = "elements";

                            data["ways"] = Object.assign(
                                {},
                                ...newSourceList1.map((element) => ({
                                    [element.name]: { ...element },
                                }))
                            );

                            const destinationList1 = Object.values(
                                data["elements"]
                            );

                            const newDestinationList1 = addItemInList(
                                destinationList1,
                                connectionIndex,
                                removedItem1
                            ) as PageType[];

                            data["elements"] = Object.assign(
                                {},
                                ...newDestinationList1.map((element) => ({
                                    [element.name]: {
                                        ...element,
                                    },
                                }))
                            );
                        }

                        const newData: ItemsPagesType = { ...data };
                        loyaltyConsommationIndex = Object.keys(
                            newData.ways
                        ).includes("loyaltyConsommation")
                            ? Object.keys(newData.ways).indexOf(
                                  "loyaltyConsommation"
                              )
                            : -1;
                        if (loyaltyConsommationIndex !== -1) {
                            const [removedElement1, newSourceList1] =
                                removeItemFromList(
                                    Object.values(newData["ways"]),
                                    loyaltyConsommationIndex
                                );

                            const removedItem1 = { ...removedElement1 };

                            removedItem1.prefix = "elements";

                            newData["ways"] = Object.assign(
                                {},
                                ...newSourceList1.map((element) => ({
                                    [element.name]: { ...element },
                                }))
                            );

                            const destinationList1 = Object.values(
                                newData["elements"]
                            );

                            const newDestinationList1 = addItemInList(
                                destinationList1,
                                loyaltyConsommationIndex,
                                removedItem1
                            ) as PageType[];

                            newData["elements"] = Object.assign(
                                {},
                                ...newDestinationList1.map((element) => ({
                                    [element.name]: {
                                        ...element,
                                    },
                                }))
                            );
                        }

                        setPagesItems(newData);
                    }
                }
                if (
                    !isCustomerAccountActive === true &&
                    (pagesItems.elements.authenticationModes as PageType) !==
                        undefined &&
                    (pagesItems.elements.connection as PageType) !==
                        undefined &&
                    (pagesItems.elements.loyaltyConsommation as PageType) !==
                        undefined
                ) {
                    const localPagesItems: ItemsPagesType = { ...pagesItems };
                    const newLocalPageItem: ItemsPagesType = {
                        ...localPagesItems,
                    };
                    const authenticationModesIndex = Object.keys(
                        newLocalPageItem.elements
                    ).includes("authenticationModes")
                        ? Object.keys(newLocalPageItem.elements).indexOf(
                              "authenticationModes"
                          )
                        : -1;
                    const connectionIndex = Object.keys(
                        newLocalPageItem.elements
                    ).includes("connection")
                        ? Object.keys(newLocalPageItem.elements).indexOf(
                              "connection"
                          )
                        : -1;
                    if (authenticationModesIndex !== -1) {
                        const [removedElement, newSourceList] =
                            removeItemFromList(
                                Object.values(newLocalPageItem["elements"]),
                                authenticationModesIndex
                            );

                        const removedItem = { ...removedElement };

                        removedItem.prefix = "ways";

                        newLocalPageItem["elements"] = Object.assign(
                            {},
                            ...newSourceList.map((element) => ({
                                [element.name]: { ...element },
                            }))
                        );

                        const destinationList = Object.values(
                            newLocalPageItem["ways"]
                        );

                        const newDestinationList = addItemInList(
                            destinationList,
                            2,
                            removedItem
                        ) as PageType[];

                        newLocalPageItem["ways"] = Object.assign(
                            {},
                            ...newDestinationList.map((element) => ({
                                [element.name]: {
                                    ...element,
                                },
                            }))
                        );
                    }

                    const data: ItemsPagesType = { ...newLocalPageItem };

                    if (connectionIndex !== -1) {
                        const [removedElement1, newSourceList1] =
                            removeItemFromList(
                                Object.values(data["elements"]),
                                connectionIndex
                            );

                        const removedItem1 = { ...removedElement1 };

                        removedItem1.prefix = "ways";

                        data["elements"] = Object.assign(
                            {},
                            ...newSourceList1.map((element) => ({
                                [element.name]: { ...element },
                            }))
                        );

                        const destinationList1 = Object.values(data["ways"]);

                        const newDestinationList1 = addItemInList(
                            destinationList1,
                            1,
                            removedItem1
                        ) as PageType[];

                        data["ways"] = Object.assign(
                            {},
                            ...newDestinationList1.map((element) => ({
                                [element.name]: {
                                    ...element,
                                },
                            }))
                        );
                    }

                    const newData: ItemsPagesType = { ...data };
                    const loyaltyConsommationIndex = Object.keys(
                        newData.elements
                    ).includes("loyaltyConsommation")
                        ? Object.keys(newData.elements).indexOf(
                              "loyaltyConsommation"
                          )
                        : -1;

                    if (loyaltyConsommationIndex !== -1) {
                        const [removedElement1, newSourceList1] =
                            removeItemFromList(
                                Object.values(newData["elements"]),
                                loyaltyConsommationIndex
                            );

                        const removedItem1 = { ...removedElement1 };

                        removedItem1.prefix = "ways";

                        newData["elements"] = Object.assign(
                            {},
                            ...newSourceList1.map((element) => ({
                                [element.name]: { ...element },
                            }))
                        );

                        const destinationList1 = Object.values(newData["ways"]);
                        const orderTakingIndex = Object.keys(
                            newData.ways
                        ).indexOf("orderTaking");
                        const newDestinationList1 = addItemInList(
                            destinationList1,
                            orderTakingIndex + 1,
                            removedItem1
                        ) as PageType[];

                        newData["ways"] = Object.assign(
                            {},
                            ...newDestinationList1.map((element) => ({
                                [element.name]: {
                                    ...element,
                                },
                            }))
                        );
                    }

                    setPagesItems(newData);
                }
            }
            if (SKIPPED_PAGES.includes(contentName)) {
                if (
                    (
                        template.content[contentName] as ProjectContentItemType
                    ).items.filter((item) => {
                        return item.active === true;
                    }).length === 1 &&
                    (template.pages.ways[contentName] as PageType).skipped ===
                        true
                ) {
                    setIsSkippedStep(false, contentName);
                }
            }
            setLocalProjectItems(
                contentName,
                array as
                    | ProjectMainContentItemType[]
                    | LanguagesItemType[]
                    | OptionsItemType[]
            );
        }
    }

    return (
        <div className="d-flex flex-column" style={{ marginLeft: "30px" }}>
            <div
                style={{
                    font: "normal normal 600 37px/49px Segoe UI",
                    marginLeft: "30px",
                    marginTop: "30px",
                }}
            >
                {activeTabTitle}
            </div>

            <div className="skipped-step-container d-flex mt-5">
                {isSwitchShown ? (
                    <ReactSwitchWrapper pageName={contentName} />
                ) : null}
            </div>

            <ScrollContainer
                style={{
                    maxHeight: "880px",
                    overflowY: "auto",
                }}
            >
                <div
                    style={{
                        margin: "30px",
                        gap: "30px",
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gridTemplateRows: "auto",
                    }}
                >
                    {items.map(
                        (
                            option:
                                | ProjectMainContentItemType
                                | LanguagesItemType
                                | OptionsItemType
                        ) => {
                            const imageSrc = getImageContentById(option.id);

                            return ![
                                "Allergen",
                                "Promo code",
                                "Calorie",
                                "Usual",
                            ].includes((option as OptionsItemType).title) ? (
                                <div key={option.id}>
                                    <span
                                        className="cursor__clz"
                                        style={{
                                            font: "normal normal normal 15px/20px Segoe UI",
                                            color: "#B2B2B2",
                                        }}
                                    >
                                        {["Options", "Languages"].includes(
                                            activeTabTitle
                                        )
                                            ? t(
                                                  (
                                                      option as
                                                          | LanguagesItemType
                                                          | OptionsItemType
                                                  ).title as string
                                              )
                                            : t(
                                                  (
                                                      option as ProjectMainContentItemType
                                                  ).name
                                              )}
                                    </span>
                                    <Card
                                        className={classnames(
                                            "ml-1 mt-1 cmn_chk_selectButton",
                                            {
                                                "disable-account-creation-items__clz":
                                                    (selectedSaleMethodDeliveryExist !==
                                                        undefined &&
                                                        selectedSaleMethodDeliveryExist.active ===
                                                            true &&
                                                        (
                                                            option as OptionsItemType
                                                        ).title ===
                                                            "Customer account") ||
                                                    ((
                                                        option as ProjectMainContentItemType
                                                    ).shortName ===
                                                        "loyaltyCard" &&
                                                        selectedShopApp.fidelityType ===
                                                            GODIA_LOYALTY),
                                            }
                                        )}
                                        style={{
                                            borderBottom: option.active
                                                ? "blue 10px solid"
                                                : "#BCBCBC 10px solid",
                                            width: "250px",
                                            height: "220px",
                                            borderRadius: "20px",
                                        }}
                                        onClick={() =>
                                            handleOnClickCheckIconEvent(option)
                                        }
                                    >
                                        <div>
                                            <CheckIcon
                                                className="ml-1 mt-1"
                                                height={45}
                                                width={45}
                                                style={{
                                                    fill: option.active
                                                        ? "blue"
                                                        : "#BCBCBC",
                                                    cursor: "pointer",
                                                }}
                                            />

                                            <div className="d-flex justify-content-center align-items-end">
                                                <img
                                                    key={option.id}
                                                    src={imageSrc}
                                                    style={{
                                                        width: "110px",
                                                        height: "110px",
                                                    }}
                                                />
                                            </div>
                                            {(option as LanguagesItemType)
                                                .isDefault === true ? (
                                                <div
                                                    style={{
                                                        minHeight: "50px",
                                                        minWidth: "50px",
                                                    }}
                                                    className="d-flex justify-content-center  align-items-center"
                                                >
                                                    <IoMdStar
                                                        style={{
                                                            width: "30px",
                                                            height: "30px",
                                                            fill: "gold",
                                                        }}
                                                    />
                                                </div>
                                            ) : null}
                                        </div>
                                    </Card>
                                </div>
                            ) : null;
                        }
                    )}
                </div>
            </ScrollContainer>
        </div>
    );
}
