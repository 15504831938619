/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { snapshot } from "valtio";

import { KeyboardInput } from "react-simple-keyboard";

import { syncStore } from "@components/Synchronization/store";

import { kioskStore as store } from "../store";

export function setCustomerLanguage(language: LanguagesItemType): void {
    store.customerLanguage = language;
}

export function setIsPrm(isPrm: boolean): void {
    store.isPrm = isPrm;
}

export function setIsHelpModalOpened(isHelpModalOpened: boolean): void {
    store.isHelpModalOpened = isHelpModalOpened;
}

export function setSaleMethodsNumber(saleMethodsNumber: number): void {
    store.saleMethodsNumber = saleMethodsNumber;
}

export function setRegulationModesNumber(regulationModesNumber: number): void {
    store.regulationModesNumber = regulationModesNumber;
}

export function setIsTopBannerActive(isTopBannerActive: boolean): void {
    store.isTopBannerActive = isTopBannerActive;
}

export function setIsInformationMessageActive(
    isInformationMessageActive: boolean
): void {
    store.isInformationMessageActive = isInformationMessageActive;
}

export function setIsUserAccountActive(isUserAccountActive: boolean): void {
    store.isUserAccountActive = isUserAccountActive;
}

export function setIsLanguagesActive(isLanguagesActive: boolean): void {
    store.isLanguagesActive = isLanguagesActive;
}

export function setActiveLanguages(activeLanguages: LanguagesItemType[]): void {
    store.activeLanguages = activeLanguages;
}

export function setIsConfigOptionsActive(isConfigOptionsActive: boolean): void {
    store.isConfigOptionsActive = isConfigOptionsActive;
}

export function setActiveConfigOptions(
    activeConfigOptions: OptionsItemType[]
): void {
    store.activeConfigOptions = JSON.parse(JSON.stringify(activeConfigOptions));
}

export function setIsLogoActive(isLogoActive: boolean): void {
    store.isLogoActive = isLogoActive;
}

export function setIsActionButtonsActive(isActionButtonsActive: boolean): void {
    store.isActionButtonsActive = isActionButtonsActive;
}

export function setIsHeaderActive(isHeaderActive: boolean): void {
    store.isHeaderActive = isHeaderActive;
}

export function setActionsButtonsItems(
    actionsButtonsItems: ActionsButtonsDesignItemType[]
): void {
    store.actionsButtonsItems = actionsButtonsItems;
}

export function setMainContentItems(
    mainContentItems:
        | ProjectMainContentItemType[]
        | PaymentMainContentItemType[]
        | printerOptionsItemsType[]
): void {
    store.mainContentItems = mainContentItems;
}

export function setInformationMessageItems(
    informationMessageItems: HeaderLanguageItemsType
): void {
    store.informationMessageItems = informationMessageItems;
}

export function setUserAccountMessageItems(
    userAccountMessageItems: HeaderLanguageItemsType
): void {
    store.userAccountMessageItems = userAccountMessageItems;
}

export function setActiveStandbyScreenItems(
    activeStandbyScreenItems: StandbyScreenItemType[]
): void {
    store.activeStandbyScreenItems = activeStandbyScreenItems;
}

export function setProjectTimeout(timeout: number): void {
    store.timeout = timeout;
}

export function setActiveActionsButtons(
    activeActionsButtons: ActionsButtonsDesignItemType[]
): void {
    store.activeActionsButtons = activeActionsButtons;
}

export function setInitialStepTimeout(initialStepTimeout: number): void {
    store.initialStepTimeout = initialStepTimeout;
}

export function setIsUserAccountModalOpened(
    isUserAccountModalOpened: boolean
): void {
    store.isUserAccountModalOpened = isUserAccountModalOpened;
}

export function setSelectedAuthMode(selectedAuthMode: string): void {
    store.selectedAuthMode = selectedAuthMode;
}

export function setChosenTransition(chosenTransition: TransitionType): void {
    store.chosenTransition = chosenTransition;
}

export function setIsAnimationIn(isAnimationIn: boolean): void {
    store.isAnimationIn = isAnimationIn;
}

export function setSelectedSaleModeValue(selectedSaleModeValue: string): void {
    store.selectedSaleModeValue = selectedSaleModeValue;
}
export function setIsClickAndCollect(isClickAndCollect: boolean): void {
    store.isClickAndCollect = isClickAndCollect;
}

export function setIsClickAndCollectOrderPaid(
    isClickAndCollectOrderPaid: boolean
): void {
    store.isClickAndCollectOrderPaid = isClickAndCollectOrderPaid;
}

export function setSelectedSaleModeId(selectedSaleModeId: string): void {
    store.selectedSaleModeId = selectedSaleModeId;
}
export function setSelectedSaleModeOrderTaking(
    selectedSaleModeOrderTaking: string
): void {
    store.selectedSaleModeOrderTaking = selectedSaleModeOrderTaking;
}

export function setKioskWays(kioskWays: WaysType[]): void {
    store.kioskWays = kioskWays;
}

export function setSelectedInformationMode(
    selectedInformationMode: string
): void {
    store.selectedInformationMode = selectedInformationMode;
}

export function setIsSubStepIconActive(isSubStepIconActive: boolean): void {
    store.isSubStepIconActive = isSubStepIconActive;
}

export function setIsSubStepTopBannerActive(
    isSubStepTopBannerActive: boolean
): void {
    store.isSubStepTopBannerActive = isSubStepTopBannerActive;
}

export function setIsSubStepLogoActive(isSubStepLogoActive: boolean): void {
    store.isSubStepLogoActive = isSubStepLogoActive;
}

export function setIsSubStepInformationMessageActive(
    isSubStepInformationMessageActive: boolean
): void {
    store.isSubStepInformationMessageActive = isSubStepInformationMessageActive;
}

export function setIsSubStepActionButtonsActive(
    isSubStepActionButtonsActive: boolean
): void {
    store.isSubStepActionButtonsActive = isSubStepActionButtonsActive;
}

export function setNavigationIndex(navigationIndex: number): void {
    store.navigationIndex = navigationIndex;
    store.isAnimationIn = false;
}

export function setAuthModalStepIndex(authModalStepIndex: number): void {
    store.authModalStepIndex = authModalStepIndex;
}

export function setProject(project: ProjectType): void {
    store.project = project;
    localStorage.setItem("project", JSON.stringify(project));
}

export function setSubStepActionButtons(
    subStepActionButtons:
        | ActionsButtonsDesignItemType[]
        | ActionsButtonsItemV2Type[]
): void {
    store.subStepActionButtons = subStepActionButtons;
}

export function setIsUserAuthenticated(isUserAuthenticated: boolean): void {
    store.isUserAuthenticated = isUserAuthenticated;
}
export function setIsProjectMustBeUpdated(isProjectMustBeUpdated: {
    setting: boolean;
    peripheral: boolean;
}): void {
    store.isProjectMustBeUpdated = isProjectMustBeUpdated;
}

export function setSubStepInformationMessageItems(
    subStepInformationMessageItems: HeaderLanguageItemsType
): void {
    store.subStepInformationMessageItems = subStepInformationMessageItems;
}

export function setSubStepIconId(subStepIconId: string): void {
    store.subStepIconId = subStepIconId;
}

export function setSelectedRegulationModeValue(
    selectedRegulationModeValue: string
): void {
    store.selectedRegulationModeValue = selectedRegulationModeValue;
}

export function setIsConfirmationModalOpened(
    isConfirmationModalOpened: boolean
): void {
    store.isConfirmationModalOpened = isConfirmationModalOpened;
}

export function setNumericKeyboardInputValue(
    numericKeyboardInputValue: string
): void {
    store.numericKeyboardInputValue = numericKeyboardInputValue;
}

export function setIsAuthenticationErrorModalOpened(
    isAuthenticationErrorModalOpened: boolean
): void {
    store.isAuthenticationErrorModalOpened = isAuthenticationErrorModalOpened;
}

export function setKeyboardInputsValues(
    keyboardInputsValues: KeyboardInput
): void {
    store.keyboardInputsValues = keyboardInputsValues;
}

export function setLogo(logo: GeneralDesignLogoType): void {
    store.logo = logo;
}

export function setIsTicketNumberMessageFooterActive(
    ticketNumberMessageActive: boolean
): void {
    store.isTicketNumberMessageFooterActive = ticketNumberMessageActive;
}

export function setTicketNumberMessageFooter(
    ticketNumberMessage: HeaderLanguageItemsType
): void {
    store.ticketNumberMessageFooter = ticketNumberMessage;
}

export function setFinalMessageFooter(
    finalMessageFooter: HeaderLanguageItemsType
): void {
    store.finalMessageFooter = finalMessageFooter;
}

export function setIsFinalMessageFooterActive(
    finalMessageFooterActive: boolean
): void {
    store.isFinalMessageFooterActive = finalMessageFooterActive;
}

export function setFinalMessage(finalMessage: string): void {
    store.finalMessage = finalMessage;
}

export function setFinalMessageSelectedRegulationMode(
    SelectedRegulationMode: string
): void {
    store.finalMessageSelectedRegulationMode = SelectedRegulationMode;
}

export function setFinalMessageSelectedInformationMode(
    SelectedInformationMode: string
): void {
    store.finalMessageSelectedInformationMode = SelectedInformationMode;
}

export function setTotalOrderAfterPaidWithLoyaltyAccountBalance(
    totalOrderAfterPaidWithLoyaltyAccountBalance: number
): void {
    store.totalOrderAfterPaidWithLoyaltyAccountBalance =
        totalOrderAfterPaidWithLoyaltyAccountBalance;
}

export function setAmountDeposited(amountDeposited: number): void {
    store.amountDeposited = amountDeposited;
}

export function setTotalAmountDeposited(totalAmountDeposited: number): void {
    store.totalAmountDeposited = totalAmountDeposited;
}

export function setLeftToPay(leftToPay: number): void {
    store.leftToPay = leftToPay;
}

export function setRenderAmount(renderAmount: number): void {
    store.renderAmount = renderAmount;
}

export function setOrderNumber(orderNumber: string): void {
    store.orderNumber = orderNumber;
}

export function setChangeMachineBalance(changeMachineBalance: number): void {
    store.changeMachineBalance = changeMachineBalance;
}

export function initStoreVariablesInStandbyScreenStep(): void {
    console.log("Store variables initialization in standby step!!!");

    const { peripheralShopApp } = snapshot(syncStore);
    if (Object.keys((peripheralShopApp?.monetics as any) || {}).length > 0) {
        const { mark } = Object.values(
            peripheralShopApp?.monetics as any
        )[0] as any;

        const isTpaValinaSowModal =
            ((
                store.project.template.content.payment as PaymentContentItemType
            ).items.filter((item) => item.name === "credit card")[0]?.languages[
                store.customerLanguage.name
            ].paymentInstructions.TPAValina?.active as boolean) &&
            mark == "Valina";

        // set empty credit card payment choice for reset Valina pop up
        if (isTpaValinaSowModal) {
            store.creditCardPaymentChoice = "";
        }
    }

    store.informationModeValueTcpos = "";
    store.selectedRegulationModeValue = "";
    store.numericKeyboardInputValue = "";
    store.selectedInformationMode = "";
    store.isUserAccountActive = false;
    store.isUserAuthenticated = false;
    store.isPrm = false;
    store.isOrderPaidWithLoyaltyAccountBalance = false;
    store.isCreatingVoucherAccepted = false;
    store.user = {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        loyaltyAccount: 0,
        loyaltyAmount: 0,
        loyaltyType: "",
        amountRatio: 0,
    };
    store.amountDeposited = 0;
    store.paymentModes = [];
    store.chosenInformationMode = {
        type: "",
        data: { infoModeUuId: "", value: "" },
    };
    store.leftToPay = 0;
    store.leftToPayAfterCashMachineValidation = 0;
    store.orderNumber = "";
    store.isAbortTransactionModalOpened = false;
    store.isBackButtonDisabled = true;
    store.isAuthenticationErrorModalOpened = false;
    store.isConfirmationModalOpened = false;
    store.isRegulationModesModalOpened = false;
    store.isAnotherPaymentModeButtonDisabled = true;
    store.isTransactionCanceled = false;
    store.isPaymentRefused = false;
    store.isUserAccountModalOpened = false;
    store.selectedSaleModeValue = "";
    store.finalMessageCounter = 0;
    store.isAmountReturned = false;
    store.isCommandInsertionProblemModalOpened = false;
    store.isAnotherPaymentModeButtonCBDisabled = true;
    store.isPaymentTechnicalProblemModalOpened = false;
    store.isPaymentTechnicalProblemDetected = false;
    store.isOrderTakingPreviousStep = false;
    store.isOrderInsertedSuccessfully = false;
    store.renderAmount = 0;
    store.orderNumber = "";
    store.regulationModesModalKey = "";
    store.qrCodeValue = "";
    store.selectedSaleModeId = "";
    store.pseudoNameValue = "";
    store.cashUuID = "";
    store.creditCardUuId = "";
    store.loyaltyUuId = "";
    store.isSelectedSaleModeWithoutInformationModes = false;
    store.selectedSaleModeOrderTaking = "";
    store.orderItemsLength = 0;
    store.saleMode = {};
    store.orderModificationMode = false;
    store.chosenLocalInformationMode = {
        type: "",
        data: { infoModeUuId: "", value: "" },
    };
    store.localSelectedInformationMode = "";
    store.finalMessageSelectedInformationMode = "none";
    store.finalMessageSelectedRegulationMode = "At the counter";
    store.totalOrderAfterPaidWithLoyaltyAccountBalance = 0;
    store.isBackWithoutCreditCardPaymentChoice = true;
    store.isAuthenticationAccepted = false;
    store.isOrderPartialPaidWithLoyaltyAccountBalance = false;
    store.selectedAuthMode = "";
    store.isInformationMessageActive = false;
    store.isOrderInsertionRefused = false;
    store.returnedAmount = 0;
    store.isAcceptAbortCBTransactionButtonDisabled = false;
    store.totalAmountDeposited = 0;
    store.isRegulationModeModalButtonClicked = false;
    store.isEventStreamProblem = false;
    store.paymentStepCounter = 0;
    store.isCreationVoucherCanceled = false;
    store.isTpeNotAvailable = false;
    store.isButtonClickedOnCBPaymentMode = {
        isAnotherPaymentModeButtonClicked: false,
        isBackPaymentModeButtonClicked: false,
    };
    store.promoValue = 0;
    store.isCashTransactionValidated = false;
    store.isInformationModeActivated = true;
    store.isValidEmail = false;
    store.isSendEmailConfirmed = false;
    store.isPrintTicket = true;
    store.isSendMailWithPdfActive = false;
    store.emailTicket = "";
    store.isSendedEmailWithSuccessModalOpened = true;
    store.isClickAndCollect = false;
    store.clickAndCollectOrderSalesMode = "";
    store.isOrderPaid = false;
    store.isRegulationModeModalGiveUpButtonClicked = false;
    store.isClickAndCollectOrderPaid = false;
    store.mainContentItems = [];
    store.isHelpModalOpened = false;
    store.isCreateAnAccountSelected = false;
}

export function setIsRegulationModesModalOpened(
    isRegulationModesModalOpened: boolean
): void {
    store.isRegulationModesModalOpened = isRegulationModesModalOpened;
}

export function setIsTpaValinaIconActive(isTpaValinaIconActive: boolean): void {
    store.isTpaValinaIconActive = isTpaValinaIconActive;
}

export function setTpaValinaIconId(tpaValinaIconId: string): void {
    store.tpaValinaIconId = tpaValinaIconId;
}

export function setIsTpaValinaInformationMessageActive(
    isTpaValinaInformationMessageActive: boolean
): void {
    store.isTpaValinaInformationMessageActive =
        isTpaValinaInformationMessageActive;
}

export function setTpaValinaInformationMessageItems(
    tpaValinaInformationMessageItems: HeaderLanguageItemsType
): void {
    store.tpaValinaInformationMessageItems = tpaValinaInformationMessageItems;
}

export function setTpaValinaActionButtons(
    tpaValinaActionButtons: ActionsButtonsDesignItemType[]
): void {
    store.tpaValinaActionButtons = tpaValinaActionButtons;
}

export function setIsTpaValinaActionButtonsActive(
    isTpaValinaActionButtonsActive: boolean
): void {
    store.isTpaValinaActionButtonsActive = isTpaValinaActionButtonsActive;
}

export function setIsBackWithoutCreditCardPaymentChoice(
    isBackWithoutCreditCardPaymentChoice: boolean
): void {
    store.isBackWithoutCreditCardPaymentChoice =
        isBackWithoutCreditCardPaymentChoice;
}

export function setCreditCardPaymentChoice(
    creditCardPaymentChoice: string
): void {
    store.creditCardPaymentChoice = creditCardPaymentChoice;
}

export function setIsAuthenticationAccepted(
    isAuthenticationAccepted: boolean
): void {
    store.isAuthenticationAccepted = isAuthenticationAccepted;
}

export function setIsOrderPaidWithLoyaltyAccountBalance(
    isOrderPaidWithLoyaltyAccountBalance: boolean
): void {
    store.isOrderPaidWithLoyaltyAccountBalance =
        isOrderPaidWithLoyaltyAccountBalance;
}

export function setIsOrderPartialPaidWithLoyaltyAccountBalance(
    isOrderPartialPaidWithLoyaltyAccountBalance: boolean
): void {
    store.isOrderPartialPaidWithLoyaltyAccountBalance =
        isOrderPartialPaidWithLoyaltyAccountBalance;
}

export function setIsLocalSettingModalOpened(
    isLocalSettingModalOpened: boolean
): void {
    store.isLocalSettingModalOpened = isLocalSettingModalOpened;
}
export function setIsAuthenticationModalOpened(
    isAuthenticationModalOpened: boolean
): void {
    store.isAuthenticationModalOpened = isAuthenticationModalOpened;
}

export function setIsLocalConfigModalOpened(
    isLocalConfigModalOpened: boolean
): void {
    store.isLocalConfigModalOpened = isLocalConfigModalOpened;
}

export function setActiveTabTitle(activeTabTitle: string): void {
    store.activeTabTitle = activeTabTitle;
}

export function setLocalProjectItems(
    name: string,
    items:
        | ProjectMainContentItemType[]
        | LanguagesItemType[]
        | OptionsItemType[]
): void {
    (
        store.localProject.template.content[name] as
            | ProjectContentItemType
            | LanguagesType
            | OptionsType
    ).items = items;
}

export function setLocalProject(localProject: ProjectType): void {
    store.localProject = JSON.parse(JSON.stringify(localProject));
}

export function setIsMenuModalOpened(isMenuModalOpened: boolean): void {
    store.isMenuModalOpened = isMenuModalOpened;
}

export function setConfigInformationModes(
    informationModes: ProjectMainContentItemType[]
): void {
    const updatedArray = (
        store.localProject.template.content
            .informationModes as ProjectContentItemType
    ).items.map((globalItem) => {
        const element = informationModes.find(
            (item) => item.id === globalItem.id
        );

        if (element !== undefined) {
            return element;
        }

        return globalItem;
    });
    (
        store.localProject.template.content
            .informationModes as ProjectContentItemType
    ).items = updatedArray;
}

export function setActiveStateScreenItems(
    items: StandbyScreenItemType[]
): void {
    store.activeStateScreenItems = items;
    if (
        Object.keys(store.project.template.pages.ways).includes("standbyScreen")
    ) {
        (
            store.localProject.template.pages.ways
                .standbyScreen as StandbyScreenPageType
        ).items = items;
    } else {
        (
            store.localProject.template.pages.elements
                .standbyScreen as StandbyScreenPageType
        ).items = items;
    }
}

export function setStandbyStateScreenItems(
    standbyStateScreenItems: StandbyScreenItemType[]
): void {
    store.standbyStateScreenItems = standbyStateScreenItems;
    (
        store.localProject.template.content
            .standbyStateScreen as StandbyStateScreenItemsType
    ).items = standbyStateScreenItems;
}

export function setStandbyScreenItems(
    standbyScreenItems: StandbyScreenItemType[]
): void {
    store.standbyScreenItems = standbyScreenItems;
}

export function setIsUsersListModalOpened(
    isUsersListModalOpened: boolean
): void {
    store.isUsersListModalOpened = isUsersListModalOpened;
}

export function setIsScanQrCodeModalOpened(
    isScanQrCodeModalOpened: boolean
): void {
    store.isScanQrCodeModalOpened = isScanQrCodeModalOpened;
}

export function setIsAbortTransactionModalOpened(
    isAbortTransactionModalOpened: boolean
): void {
    store.isAbortTransactionModalOpened = isAbortTransactionModalOpened;
}

export function setRegulationModesModalKey(
    regulationModesModalKey: string
): void {
    store.regulationModesModalKey = regulationModesModalKey;
}

export function setLeftToPayAfterCashMachineValidation(
    leftToPayAfterCashMachineValidation: number
): void {
    store.leftToPayAfterCashMachineValidation =
        leftToPayAfterCashMachineValidation;
}

export function setQrCodeValue(qrCodeValue: string): void {
    store.qrCodeValue = qrCodeValue;
}

export function setUser(user: UserType): void {
    store.user = user;
}

export function setPseudoNameValue(pseudoNameValue: string): void {
    store.pseudoNameValue = pseudoNameValue;
}

export function setChosenInformationMode(
    chosenInformationMode: InformationModeType
): void {
    store.chosenInformationMode = chosenInformationMode;
}

export function setPaymentModes(paymentModes: PaymentModesType): void {
    console.log("inside setPaymentModes", JSON.stringify(paymentModes));
    store.paymentModes = JSON.parse(JSON.stringify(paymentModes));
    const totalPaymentAmount = paymentModes.reduce(
        (acc: number, curr) =>
            acc + curr.paymentMode !== "loyalty" ? curr.paymentAmount : 0,
        0
    );
    if (totalPaymentAmount > 0) {
        store.isOrderPaid = true;
    }
}

export function setCashUuID(cashUuID: string): void {
    store.cashUuID = cashUuID;
}

export function setCreditCardUuId(creditCardUuId: string): void {
    store.creditCardUuId = creditCardUuId;
}

export function setLoyaltyUuId(loyaltyUuId: string): void {
    store.loyaltyUuId = loyaltyUuId;
}

export function setIsCreatingVoucherAccepted(
    isCreatingVoucherAccepted: boolean
): void {
    store.isCreatingVoucherAccepted = isCreatingVoucherAccepted;
}

export function setIsCloseAppModalOpened(isCloseAppModalOpened: boolean): void {
    store.isCloseAppModalOpened = isCloseAppModalOpened;
}
export function setIsShutdownModalOpened(isShutdownModalOpened: boolean): void {
    store.isShutdownModalOpened = isShutdownModalOpened;
}
export function setIsSelectedSaleModeWithoutInformationModes(
    isSelectedSaleModeWithoutInformationModes: boolean
): void {
    store.isSelectedSaleModeWithoutInformationModes =
        isSelectedSaleModeWithoutInformationModes;
}

export function setIsBackButtonDisabled(isBackButtonDisabled: boolean): void {
    store.isBackButtonDisabled = isBackButtonDisabled;
}

export function setIsAnotherPaymentModeButtonDisabled(
    isAnotherPaymentModeButtonDisabled: boolean
): void {
    store.isAnotherPaymentModeButtonDisabled =
        isAnotherPaymentModeButtonDisabled;
}

export function setIsFenceProblemDetected(
    isFenceProblemDetected: boolean
): void {
    store.isFenceProblemDetected = isFenceProblemDetected;
}

export function setTechnicalProblemKey(technicalProblemKey: string): void {
    store.technicalProblemKey = technicalProblemKey;
}

export function setShopOpeningHour(shopOpeningHour: any[]): void {
    store.shopOpeningHour = shopOpeningHour;
}

export function setIsTransactionCanceled(isTransactionCanceled: boolean): void {
    store.isTransactionCanceled = isTransactionCanceled;
}

export function setIsPaymentRefused(isPaymentRefused: boolean): void {
    store.isPaymentRefused = isPaymentRefused;
}

export function setVoucherAmount(voucherAmount: number): void {
    store.voucherAmount = voucherAmount;
}

export function setIsCreationVoucherCanceled(
    isCreationVoucherCanceled: boolean
): void {
    store.isCreationVoucherCanceled = isCreationVoucherCanceled;
}

export function setPagesItems(pagesItems: ItemsPagesType): void {
    store.pagesItems = JSON.parse(JSON.stringify(pagesItems));
    store.localProject.template.pages = JSON.parse(JSON.stringify(pagesItems));
}

export function setIsCashTransactionValidated(
    isCashTransactionValidated: boolean
): void {
    store.isCashTransactionValidated = isCashTransactionValidated;
}

export function setIsAnotherPaymentModeButtonCBDisabled(
    isAnotherPaymentModeButtonCBDisabled: boolean
): void {
    store.isAnotherPaymentModeButtonCBDisabled =
        isAnotherPaymentModeButtonCBDisabled;
}
export function setOrderItemsLength(orderItemsLength: number): void {
    store.orderItemsLength = orderItemsLength;
}

export function setIsPaymentTechnicalProblemModalOpened(
    isPaymentTechnicalProblemModalOpened: boolean
): void {
    store.isPaymentTechnicalProblemModalOpened =
        isPaymentTechnicalProblemModalOpened;
}

export function setIsPaymentTechnicalProblemDetected(
    isPaymentTechnicalProblemDetected: boolean
): void {
    store.isPaymentTechnicalProblemDetected = isPaymentTechnicalProblemDetected;
}

export function setIsCommandInsertionProblemModalOpened(
    isCommandInsertionProblemModalOpened: boolean
): void {
    store.isCommandInsertionProblemModalOpened =
        isCommandInsertionProblemModalOpened;
}

export function setFinalMessageCounter(finalMessageCounter: number): void {
    store.finalMessageCounter = finalMessageCounter;
}

export function setIsAmountReturned(isAmountReturned: boolean): void {
    store.isAmountReturned = isAmountReturned;
    if (isAmountReturned) {
        store.isOrderPaid = false;
    }
}

export function setIsInformationModesModalOpened(
    isInformationModesModalOpened: boolean
): void {
    store.isInformationModesModalOpened = isInformationModesModalOpened;
}

export function setIsInformationModesConsommationModalOpened(
    isInformationModesConsommationModalOpened: boolean
): void {
    store.isInformationModesConsommationModalOpened =
        isInformationModesConsommationModalOpened;
}

export function setSaleMode(saleMode: any): void {
    store.saleMode = saleMode;
}

export function setOrderModificationMode(): void {
    store.orderModificationMode = !store.orderModificationMode;
}

export function setChosenLocalInformationMode(
    chosenLocalInformationMode: InformationModeType
): void {
    store.chosenLocalInformationMode = chosenLocalInformationMode;
}
export function setLocalSelectedInformationMode(
    localSelectedInformationMode: string
): void {
    store.localSelectedInformationMode = localSelectedInformationMode;
}

export function setIsOrderTakingPreviousStep(
    isOrderTakingPreviousStep: boolean
): void {
    store.isOrderTakingPreviousStep = isOrderTakingPreviousStep;
}

export function setIsOrderInsertedSuccessfully(
    isOrderInsertedSuccessfully: boolean
): void {
    store.isOrderInsertedSuccessfully = isOrderInsertedSuccessfully;
}

export function setReturnedAmount(returnedAmount: number): void {
    store.returnedAmount = returnedAmount;
}

export function setIsOrderInsertionRefused(
    isOrderInsertionRefused: boolean
): void {
    store.isOrderInsertionRefused = isOrderInsertionRefused;
}

export function setIsLogoutProblemModalOpened(
    isLogoutProblemModalOpened: boolean
): void {
    store.isLogoutProblemModalOpened = isLogoutProblemModalOpened;
}

export function setIsAcceptAbortCBTransactionButtonDisabled(
    isAcceptAbortCBTransactionButtonDisabled: boolean
): void {
    store.isAcceptAbortCBTransactionButtonDisabled =
        isAcceptAbortCBTransactionButtonDisabled;
}

export function setIsCashMachineTransactionCanceled(
    isCashMachineTransactionCanceled: boolean
): void {
    store.isCashMachineTransactionCanceled = isCashMachineTransactionCanceled;
}
export function setIsOrderLoading(isloading: boolean): void {
    store.isOrderLoading = isloading;
}

export function setIsWorkflowLoading(isWorkflowLoading: boolean): void {
    store.isWorkflowLoading = isWorkflowLoading;
}

export function setIsAnimationTimeOut(isAnimationTimeOut: boolean): void {
    store.isAnimationTimeOut = isAnimationTimeOut;
}

export function setIsRegulationModesModalButtonClicked(
    isRegulationModeModalButtonClicked: boolean
): void {
    store.isRegulationModeModalButtonClicked =
        isRegulationModeModalButtonClicked;
}

export function setIsEventStreamProblem(isEventStreamProblem: boolean): void {
    store.isEventStreamProblem = isEventStreamProblem;
}

export function setChangeMachineConfigurationDate(
    changeMachineConfigurationDate: string
): void {
    store.changeMachineConfigurationDate = changeMachineConfigurationDate;
}

export function setLocalFinalMessageSelectedInformationMode(
    localSelectedInformationMode: string
): void {
    store.localFinalMessageSelectedInformationMode =
        localSelectedInformationMode;
}

export function setPaymentStepCounter(paymentStepCounter: number): void {
    store.paymentStepCounter = paymentStepCounter;
}

export function setIsKeyboardOpened(isKeyboardOpened: boolean): void {
    store.isKeyboardOpened = isKeyboardOpened;
}

export function setUserAuthInfo(userAuthInfo: AuthModeInfoType): void {
    store.userAuthInfo = userAuthInfo;
}

export function setIsGlobalSummaryOrderOpened(
    isGlobalSummaryOrderOpened: boolean
): void {
    store.isGlobalSummaryOrderOpened = isGlobalSummaryOrderOpened;
}

export function setIsTpeNotAvailable(isTpeNotAvailable: boolean): void {
    store.isTpeNotAvailable = isTpeNotAvailable;
}
export function setIsTpeWaitingTimeReached(
    isTpeWaitingTimeReached: boolean
): void {
    store.isTpeWaitingTimeReached = isTpeWaitingTimeReached;
}

export function setIsButtonClickedOnCBPaymentMode(isButtonClickedOnCBPaymentMode: {
    isAnotherPaymentModeButtonClicked: boolean;
    isBackPaymentModeButtonClicked: boolean;
}): void {
    store.isButtonClickedOnCBPaymentMode = isButtonClickedOnCBPaymentMode;
}

export function setPromoValue(promoValue: number): void {
    store.promoValue = promoValue;
}

export function setIsTcposOrderValidated(isTcposOrderValidated: boolean): void {
    store.isTcposOrderValidated = isTcposOrderValidated;
}
export function setIsLoadingModalOpened(isLoadingModalOpened: boolean): void {
    store.isLoadingModalOpened = isLoadingModalOpened;
}

export function setTcposCancelOrderStatus(
    isTcposCancelOrderOngoing: boolean
): void {
    store.isTcposCancelOrderOngoing = isTcposCancelOrderOngoing;
}

export function setIsAccessNotAllowed(isAccessNotAllowed: boolean): void {
    store.isAccessNotAllowed = isAccessNotAllowed;
}

export function setIsTcposOrderRefreshDone(
    isTcposOrderRefreshDone: boolean
): void {
    store.isTcposOrderRefreshDone = isTcposOrderRefreshDone;
}
export function setIsSendEmailConfirmed(isSendEmailConfirmed: boolean): void {
    store.isSendEmailConfirmed = isSendEmailConfirmed;
}
export function setIsPrintTicket(isPrintTicket: boolean): void {
    store.isPrintTicket = isPrintTicket;
}
export function setEmailTicket(emailTicket: string): void {
    store.emailTicket = emailTicket;
}
export function setIsValidEmail(isValidEmail: boolean): void {
    store.isValidEmail = isValidEmail;
}
export function setIsSendMailWithPdfActive(
    isSendMailWithPdfActive: boolean
): void {
    store.isSendMailWithPdfActive = isSendMailWithPdfActive;
}
export function setIsSendedEmailWithSuccessModalOpened(
    isSendedEmailWithSuccessModalOpened: boolean
): void {
    store.isSendedEmailWithSuccessModalOpened =
        isSendedEmailWithSuccessModalOpened;
}
export function setIsTicketNumberActive(isTicketNumberActive: boolean): void {
    store.isTicketNumberActive = isTicketNumberActive;
}
export function setIsLedActive(isLedActive: boolean): void {
    store.isLedActive = isLedActive;
}
export function setConnectedLedPort(connectedLedPort: string): void {
    store.connectedLedPort = connectedLedPort;
}

export function setOrderTotalPrice(orderTotalPrice: number): void {
    store.orderTotalPrice = orderTotalPrice;
}

export function setClickAndCollectOrderId(
    clickAndCollectOrderId: string
): void {
    store.clickAndCollectOrderId = clickAndCollectOrderId;
}

export function setClickAndCollectOrderSalesMode(
    clickAndCollectOrderSalesMode: string
): void {
    store.clickAndCollectOrderSalesMode = clickAndCollectOrderSalesMode;
}

export function setIsInformationModeActivated(
    isInformationModeActivated: boolean
): void {
    store.isInformationModeActivated = isInformationModeActivated;
}

export function setIsRegulationModeModalGiveUpButtonClicked(
    isRegulationModeModalGiveUpButtonClicked: boolean
): void {
    store.isRegulationModeModalGiveUpButtonClicked =
        isRegulationModeModalGiveUpButtonClicked;
}

export function setInformationModeValueTcpos(
    informationModeValueTcpos: string
): void {
    store.informationModeValueTcpos = informationModeValueTcpos;
}
export function setIsSkippedStep(
    isSkippedStep: boolean,
    pageName: string
): void {
    if (
        (store.project.template.pages.ways[pageName] as PageType) !== undefined
    ) {
        ((store.localProject.template.pages.ways[pageName] as PageType)
            .skipped as boolean) = isSkippedStep;
    } else {
        ((store.localProject.template.pages.elements[pageName] as PageType)
            .skipped as boolean) = isSkippedStep;
    }
}

//! to verify

export function setIsSkippedStepInformationModes(
    isSkippedStep: boolean,
    selectedSaleModeValue: string
): void {
    if (
        (store.localProject.template.pages.ways[
            "informationModes"
        ] as PageType) !== undefined
    ) {
        ((
            (
                store.localProject.template.pages.ways
                    .informationModes as PageType
            ).skipped as ItemContentType
        )[selectedSaleModeValue] as boolean) = isSkippedStep;
    } else {
        ((
            (
                store.localProject.template.pages.elements
                    .informationModes as PageType
            ).skipped as ItemContentType
        )[selectedSaleModeValue] as boolean) = isSkippedStep;
    }
}
export function setDevise(devise: DeviseType): void {
    store.devise = devise;
}

export function setLocalShopCartOpened(localShopCartOpened: boolean): void {
    store.localShopCartOpened = localShopCartOpened;
}

export function setIsKeyBoardOpened(isKeyBoardOpened: boolean): void {
    store.isKeyBoardOpened = isKeyBoardOpened;
}
export function setIsCustomerBenefitOpen(isCustomerBenefitOpen: boolean): void {
    store.isCustomerBenefitOpen = isCustomerBenefitOpen;
}
export function setIsCustomerBenefitOnClick(
    isCustomerBenefitOnClick: boolean
): void {
    store.isCustomerBenefitOnClick = isCustomerBenefitOnClick;
}

export function setCashMachineStatus(cashMachineStatus: {
    isOpened: boolean;
    isNotAvailable: boolean;
    isBillRefund: boolean;
    isRefundProblem: boolean;
    isOccupied: boolean;
    isCoinsRefund: boolean;
    isNetworkProblem: boolean;
}): void {
    store.cashMachineStatus = cashMachineStatus;
}

export function setIsCreateAnAccountSelected(
    isCreateAnAccountSelected: boolean
): void {
    store.isCreateAnAccountSelected = isCreateAnAccountSelected;
    store.isSubStepInformationMessageActive = (
        store.project.template.pages.subSteps["accountFidelite"] as PageType
    ).header.informationMessage.active;

    store.subStepInformationMessageItems = // eslint-disable-next-line
    (
        store.project.template.pages.subSteps["accountFidelite"] as any
    ).header.informationMessage.languages;

    store.isTopBannerActive = (
        store.project.template.pages.subSteps["accountFidelite"] as PageType
    ).topBanner.active;

    store.isLogoActive = // eslint-disable-next-line
    (
        store.project.template.pages.subSteps["accountFidelite"] as any
    ).topBanner.logo;
    store.isSubStepActionButtonsActive =
        // eslint-disable-next-line
        (
            (store.project.template.pages.subSteps["accountFidelite"] as any)
                .actionsButtons as ActionsButtonsItemV2Type[]
        ).length !== 0;
    store.subStepActionButtons = // eslint-disable-next-line
    (store.project.template.pages.subSteps["accountFidelite"] as any)
        .actionsButtons as ActionsButtonsItemV2Type[];
}

export function setLoyaltyType(loyaltyType: string): void {
    store.loyaltyType = loyaltyType;
}
