import { CATEGORY_TYPE, ITEM_TYPE } from "@constants";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { kioskStore } from "@pages/Kiosk/store";

import { MemoizedProductImage } from "./ProductImage";

export function FirstBoxRedirectCard({ handleAddProductClick, product }) {
    const { isPrm } = useSnapshot(kioskStore);
    const { design } = useSelector((state) => state.orderSlice, shallowEqual);

    const { workflowData } = useSelector((state) => state.workFlowDataSlice);

    const nbrRows =
        workflowData.length === 0
            ? design.nbrRows
            : workflowData[workflowData.length - 1].workflow[
                  workflowData[workflowData.length - 1]?.index
              ].nbrRows;

    const { isProductDescriptionActive } = useSelector(
        (state) => state.settingSlice.productSetting,
        shallowEqual
    );

    const { isCategorySubCategoryTitleActive } = useSelector(
        (state) => state.settingSlice.productSetting,
        shallowEqual
    );

    const isSubCategoryTitleActive =
        isCategorySubCategoryTitleActive &&
        product.type === CATEGORY_TYPE &&
        (product.isRedirect === undefined || product.isRedirect === false)
            ? product?.isNameDisplayed !== undefined
                ? product?.isNameDisplayed
                : true
            : false;

    const isProductTitleVisible =
        product.type === ITEM_TYPE ||
        (product.type === CATEGORY_TYPE && product.isRedirect === true);

    const isItemDesignationShown = product.isTitleShow;
    const isTitleActive =
        (isProductTitleVisible && isItemDesignationShown) ||
        isSubCategoryTitleActive;

    const isPriceShown =
        product.type !== CATEGORY_TYPE ||
        (product.type === CATEGORY_TYPE &&
            product.isRedirect === true &&
            product.price.content > 0);

    const isProductDesciptionShown = isProductDescriptionActive || isPriceShown;

    const firstBoxHeight = !isPrm
        ? !isTitleActive && !isProductDesciptionShown
            ? product.heightOfImage + 100
            : !isTitleActive || !isProductDesciptionShown
            ? product.heightOfImage + 50
            : product.heightOfImage
        : ((!isTitleActive && !isProductDesciptionShown) ||
              !isTitleActive ||
              !isProductDesciptionShown) &&
          "220px";
    return (
        <div
            className="first-box"
            onClick={() => {
                handleAddProductClick();
            }}
        >
            <div
                style={{
                    width: "100%",
                    flex: 80,
                    height: isPrm || nbrRows > 4 ? "60%" : "auto",
                    minHeight: firstBoxHeight,
                    minWidth: !isPrm && product.widthOfImage,
                    objectFit: firstBoxHeight === true ? "cover" : "contain",
                }}
            >
                <div
                    className="d-flex justify-content-end  align-items-start "
                    style={{
                        position: "relative",
                        minHeight: !isPrm && product.heightOfImage,
                        minWidth: !isPrm && product.widthOfImage,
                    }}
                >
                    <MemoizedProductImage
                        productId={product.iuud}
                        productType={product.type}
                        url={product.image}
                        heightOfImage={isPrm ? "min-content" : "100%"}
                        widthOfImage={"100%"}
                    />

                    {!(product.outOfStock || product.isSuspended) && (
                        <div className="d-flex align-items-start justify-content-end">
                            <div
                                className="d-flex align-items-end justify-content-end"
                                style={{
                                    position: "absolute",
                                    zIndex: "9999",
                                    bottom: "3px",
                                    width: "100%",
                                    height: nbrRows > 4 ? "20%" : "15%",
                                    maxHeight: "35px",
                                    paddingRight: "7px",
                                }}
                            ></div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

FirstBoxRedirectCard.propTypes = {
    product: PropTypes.object.isRequired,
    handleAddProductClick: PropTypes.func,
};
