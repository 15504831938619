import {
    BASIC_COMPOSITION_TYPE,
    IS_ADDITIONAL_TYPE,
    IS_WORKFLOW_TYPE,
    TCPOS,
} from "@constants";
import Collapse from "@mui/material/Collapse";
import { t } from "i18next";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { store } from "@store";

import { ErrorToast } from "@components/common/Toast";
import { ConfirmationRemoveProductModal } from "@components/ConfirmationRemoveProductModal";
import { AddProductConfirmation } from "@components/OrderTaking/Components/Dialogs/AddProductConfirmation";
import { WorkflowDialog } from "@components/OrderTaking/Components/Dialogs/WorkflowDialog";
import { AddIconWrapper } from "@components/OrderTaking/Components/Icons/AddIcon";
import MessageIcon from "@components/OrderTaking/Components/Icons/MessageIcon";
import { ModifyIcon } from "@components/OrderTaking/Components/Icons/ModifyIcon";
import { RemoveIcon } from "@components/OrderTaking/Components/Icons/RemoveIcon";
import {
    addItemCommandTcPos,
    groupWorkflowShopCard,
    productHaveItemOutOfStock,
    refactorWorkflowData,
    removeItemCommandTcPos,
    useToggle,
} from "@components/OrderTaking/Helpers";
import { imageWithFallback } from "@components/OrderTaking/Helpers/Commonfunction";
import {
    addQuantityOrderItem,
    addToOrderworkflow,
    ClearWorkflowItem,
    minusNbrChoiceOfCurrentStep,
    removeFromOrder,
    removeFromSelectedAdditionalItems,
    removeFromWorkFlowItemShopCart,
    SelectProduct,
    setIsCommentKeyboardActive,
    setIsModification,
    setProductBeforeUpdate,
    setWorkflowData,
} from "@components/OrderTaking/Store/feature";

import { kioskStore } from "@pages/Kiosk/store";

import "./index.css";
import { MenuCompositionsLabels } from "./MenuCompositionsLabels";
import ProductCardPopup from "./ProductCardPopup";

export default function ComposedProductCartPopup({
    product,
    closeOrderSummury,
    isRemovedProductDialog,
    isWorkflow,
    isSalesModeChange,
}) {
    const { posEditor } = useSnapshot(store);
    const dispatchStore = useDispatch();
    const {
        isCommentProductActive,
        isProductModificationActive,
        isArticleDetailsAsTextActive,
        isConfirmationPopupForDeletingAnArticleActive,
    } = useSelector((state) => state.settingSlice.productSetting, shallowEqual);

    const [
        isAddProductConfirmationDialogOpened,
        setIsAddProductConfirmationDialogOpened,
    ] = useToggle();

    const [isExpanded, setIsExpanded] = useToggle();
    const [isWorkflowDialogOpened, setIsWorkflowDialogOpened] = useToggle();

    const [
        isConfirmationRemoveProductModalOpen,
        setIsConfirmationRemoveProductModalOpen,
    ] = React.useState(false);

    const { globalcard, cardType } = useSelector(
        (state) => state.orderSlice,
        shallowEqual
    );

    const { isClickAndCollect, devise } = useSnapshot(kioskStore);
    const isEditButton =
        isProductModificationActive &&
        isWorkflow !== IS_ADDITIONAL_TYPE &&
        (product.haveBasicComposition || product.haveWorkflow);

    const isExpandMode =
        Object.keys(product.shopCart).length > 1 ||
        (Object.keys(product.shopCart).length === 1 &&
            ((product.shopCart[BASIC_COMPOSITION_TYPE]?.compositions !==
                undefined &&
                Object.keys(
                    product.shopCart[BASIC_COMPOSITION_TYPE]["compositions"]
                ).length > 0) ||
                Object.keys(product.shopCart)[0] !== BASIC_COMPOSITION_TYPE));

    function removeProduct() {
        if (isConfirmationPopupForDeletingAnArticleActive) {
            setIsConfirmationRemoveProductModalOpen(true);
            return;
        }
        handleRemoveProduct();
    }

    function handleRemoveProduct() {
        switch (isWorkflow) {
            case IS_WORKFLOW_TYPE:
                dispatchStore(
                    removeFromWorkFlowItemShopCart({ product, cardType })
                );
                dispatchStore(minusNbrChoiceOfCurrentStep());
                break;
            case IS_ADDITIONAL_TYPE:
                dispatchStore(removeFromSelectedAdditionalItems(product));
                break;

            default:
                if (TCPOS === posEditor) {
                    dispatchStore(removeItemCommandTcPos(product));
                } else {
                    dispatchStore(removeFromOrder(product));
                }
        }
    }
    const handleWorkDialogCloseEvent = () => {
        dispatchStore(ClearWorkflowItem());
        dispatchStore(SelectProduct({}));
        dispatchStore(setWorkflowData([]));
        setIsWorkflowDialogOpened();
        closeOrderSummury && closeOrderSummury();
    };

    const handleOnCancelAddConfirmation = () => {
        dispatchStore(setIsModification(false));

        dispatchStore(
            addToOrderworkflow({
                ...product,
                date: Date.now(),
                price: product.defaultPrice,
                quantity: 1,
                shopCart: {},
                workflow: {},
            })
        );
        dispatchStore(setWorkflowData([]));
        dispatchStore(SelectProduct({ ...product }));
        // dispatchStore(SelectProduct(globalcard.items[product.iuud]));
        setIsWorkflowDialogOpened();
    };

    const isOutOfStockMenu = globalcard.items[product.iuud].outStock;
    const actionsButtonHided = isClickAndCollect || product.disabled;

    function addProduct() {
        const isOutOfStock = productHaveItemOutOfStock(
            product,
            globalcard.items
        );
        if (
            Object.keys(product.shopCart).length !== 0 ||
            product.haveWorkflow ||
            product.haveBasicComposition
        ) {
            if (isOutOfStockMenu) {
                toast.dismiss();
                ErrorToast(t("Item out of stock"));
                return;
            }

            if (isOutOfStock) {
                handleOnCancelAddConfirmation();
                return;
            }
            setIsAddProductConfirmationDialogOpened();
            return;
        }
        if (TCPOS === posEditor) {
            dispatchStore(addItemCommandTcPos(product));
            return;
        }
        dispatchStore(addQuantityOrderItem(product));
    }
    function handleAddProductOnCLickEvent() {
        setIsAddProductConfirmationDialogOpened();
    }

    function handleModifyProductOnCLickEvent(Item) {
        let newItem =
            Item.modifier && Item.compositions
                ? Object.values(Item.compositions)[0]
                : Item;

        dispatchStore(setIsModification(true));
        dispatchStore(setProductBeforeUpdate({ ...product }));
        dispatchStore(
            addToOrderworkflow({
                ...product,
            })
        );
        // ! to review
        // dispatchStore(
        //     clearComment("")
        // );

        const workflowModify = refactorWorkflowData(
            newItem.workflow,
            product.shopCart,
            globalcard.items
        );
        dispatchStore(setWorkflowData([...workflowModify]));

        if (Item.modifier) {
            dispatchStore(
                SelectProduct({
                    ...globalcard.items[newItem.iuud],
                    modifier: newItem.modifier,
                })
            );
        }

        setIsWorkflowDialogOpened();
    }
    function handleShowDetailOnClickEvent() {
        if (
            Object.keys(product.shopCart).length === 0 ||
            (Object.keys(product.shopCart).length === 1 &&
                product.shopCart[BASIC_COMPOSITION_TYPE]["compositions"] !==
                    undefined &&
                Object.keys(
                    product.shopCart[BASIC_COMPOSITION_TYPE]["compositions"]
                ).length === 0)
        ) {
            dispatchStore(ClearWorkflowItem());

            dispatchStore(setIsModification(true));
            dispatchStore(addToOrderworkflow(product));

            dispatchStore(setWorkflowData([]));
            dispatchStore(SelectProduct(product));
            dispatchStore(setProductBeforeUpdate(product));
            setIsWorkflowDialogOpened(true);
            return;
        }
    }

    function handleAddCommentOnClickEvent() {
        dispatchStore(
            setIsCommentKeyboardActive({ active: true, product: product })
        );
    }

    return (
        <>
            {isWorkflowDialogOpened && (
                <WorkflowDialog
                    open={isWorkflowDialogOpened}
                    onClose={handleWorkDialogCloseEvent}
                />
            )}

            <AddProductConfirmation
                open={isAddProductConfirmationDialogOpened}
                onClose={handleAddProductOnCLickEvent}
                product={product}
                onCancel={handleOnCancelAddConfirmation}
            />
            {isConfirmationRemoveProductModalOpen ? (
                <ConfirmationRemoveProductModal
                    setIsConfirmationRemoveProductModalOpen={
                        setIsConfirmationRemoveProductModalOpen
                    }
                    isConfirmationRemoveProductModalOpen={
                        isConfirmationRemoveProductModalOpen
                    }
                    callback={handleRemoveProduct}
                />
            ) : null}
            <div
                className="p-4 composed-product"
                style={{
                    maxHeight: !isExpanded ? "400px" : "",

                    minHeight: !isExpanded ? "max-content" : "",
                }}
            >
                <div className="header-composed-product mb-4">
                    <div className="d-flex flex-column">
                        <div className="d-flex justify-content-between">
                            <div
                                className="d-flex align-items-center "
                                style={{
                                    gap: "0.8rem",
                                }}
                            >
                                <div
                                    className="d-flex h-100"
                                    style={{
                                        flexDirection: isSalesModeChange
                                            ? "row-reverse"
                                            : "",
                                    }}
                                >
                                    <div className="d-flex align-items-center justify-content-center title-product mx-2">
                                        <span className="number-product">
                                            {product.quantity} X
                                        </span>
                                    </div>

                                    <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{
                                            maxWidth: "120px",
                                            maxHeight: "120px",
                                        }}
                                    >
                                        <img
                                            src={product.urlImage}
                                            onError={imageWithFallback}
                                            style={{
                                                borderRadius: "10%",
                                                height: "auto",
                                                width: "100%",
                                            }}
                                        />
                                    </div>
                                </div>

                                <div
                                    className="d-flex flex-column"
                                    style={{ gap: "5px" }}
                                >
                                    <span className="recap-product-title two-lines-preview-text">{`${product.displayTitle}`}</span>
                                    <span className="summary-options-product">
                                        {` ${
                                            product.selectedOptions
                                                ? product.selectedOptions?.join(
                                                      ","
                                                  )
                                                : ""
                                        }`}
                                    </span>

                                    {!isSalesModeChange ? (
                                        <>
                                            <div className="recap-price-product">
                                                {product?.price.toFixed(
                                                    devise.decimalPrice
                                                ) + ` ${devise.deviseSymbole}`}
                                            </div>

                                            {isExpandMode &&
                                            isArticleDetailsAsTextActive ? (
                                                <MenuCompositionsLabels
                                                    compositions={
                                                        product.shopCart
                                                    }
                                                    shopCart={true}
                                                />
                                            ) : null}
                                        </>
                                    ) : product.isDeleted ? (
                                        <div className="d-flex w-25">
                                            <img
                                                style={{
                                                    width: "30px",
                                                    height: "30px",
                                                }}
                                                src="./images/crossIcon.png"
                                            />
                                            <span
                                                style={{
                                                    font: "normal normal bold 12px/21px Segoe UI",
                                                    textAlign: "center",
                                                    width: "166px",
                                                    color: "#ff0000",
                                                }}
                                            >
                                                {t("will be deleted")}
                                            </span>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            {!isSalesModeChange ? (
                                <>
                                    <div
                                        className="recap-product-operations align-items-center"
                                        style={{
                                            height: "fit-content",
                                            gap: "10px",
                                        }}
                                    >
                                        {isCommentProductActive === true &&
                                        product?.haveComment === true &&
                                        !isRemovedProductDialog &&
                                        !product.disabled ? (
                                            <div
                                                className="d-flex justify-content-center align-content-center p-1"
                                                style={{
                                                    width: "55px",
                                                    height: "55px",

                                                    borderRadius: " 10px",
                                                }}
                                            >
                                                <MessageIcon
                                                    width="45"
                                                    height="45"
                                                    color={
                                                        product.comment !== ""
                                                            ? "var(--global-color)"
                                                            : "gray"
                                                    }
                                                    onClick={
                                                        handleAddCommentOnClickEvent
                                                    }
                                                />
                                            </div>
                                        ) : null}
                                        {actionsButtonHided ? null : (
                                            <div className="d-flex">
                                                <RemoveIcon
                                                    width={72}
                                                    height={69}
                                                    onClick={removeProduct}
                                                ></RemoveIcon>
                                                {!isRemovedProductDialog &&
                                                    !product.isReward && (
                                                        <AddIconWrapper
                                                            opacity={"1"}
                                                            width={72}
                                                            height={69}
                                                            onClick={addProduct}
                                                        />
                                                    )}
                                            </div>
                                        )}
                                    </div>
                                </>
                            ) : null}
                        </div>
                        {!isSalesModeChange &&
                            !isRemovedProductDialog &&
                            isCommentProductActive === true &&
                            product?.haveComment && (
                                <div className="justify-content-start">
                                    <div
                                        className="composed-product-comment m-3 px-3"
                                        style={{
                                            width: "98%",
                                            height: "max-content",
                                        }}
                                        onClick={handleAddCommentOnClickEvent}
                                    >
                                        <span
                                            className="d-flex justify-content-center pl-4"
                                            style={{
                                                font: "normal 600 30px/48px Segoe UI",
                                            }}
                                        >
                                            {product.comment != "" ? (
                                                product.comment
                                            ) : (
                                                <span
                                                    style={{
                                                        color: "#646060",
                                                        font: "normal normal 600 24px/49px Segoe UI",
                                                    }}
                                                >
                                                    {t("Comment...")}
                                                </span>
                                            )}
                                        </span>
                                        {!isClickAndCollect ? (
                                            <div className="d-flex flex-column">
                                                <ModifyIcon></ModifyIcon>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
                {isExpandMode ? (
                    <>
                        <div
                            className={isExpanded ? "mb-5" : ""}
                            style={{
                                overflowY: "auto",
                                scrollbarWidth: "none",
                            }}
                        >
                            <Collapse in={isExpanded}>
                                {Object.keys(product.shopCart)
                                    .sort((a, b) => {
                                        return a.stepRank - b.stepRank;
                                    })
                                    .map((key) => {
                                        const groupedItems =
                                            groupWorkflowShopCard(
                                                product.shopCart[key]
                                                    .compositions
                                            );
                                        const compositionsValues =
                                            product.shopCart[key].compositions;
                                        if (groupedItems.length === 0) {
                                            return null;
                                        }

                                        return (
                                            <div
                                                className="d-flex flex-column align-items-center"
                                                key={key}
                                            >
                                                <div className="title-element my-2">
                                                    {key ===
                                                    "composition de base"
                                                        ? t(
                                                              "BASIC COMPOSITION"
                                                          ).toUpperCase()
                                                        : Object.values(
                                                              compositionsValues
                                                          )[0].stepDisplayTitle}
                                                </div>
                                                {groupedItems &&
                                                    groupedItems.map(
                                                        (Item, index) => {
                                                            return (
                                                                <React.Fragment
                                                                    key={index}
                                                                >
                                                                    {Item.name ? (
                                                                        <ProductCardPopup
                                                                            Item={
                                                                                Item
                                                                            }
                                                                            product={
                                                                                product
                                                                            }
                                                                            Editproduct={
                                                                                handleModifyProductOnCLickEvent
                                                                            }
                                                                            isSalesModeChange={
                                                                                isSalesModeChange
                                                                            }
                                                                            isCommentProductActive={
                                                                                isCommentProductActive
                                                                            }
                                                                            isWorkflow={
                                                                                isWorkflow
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        Object.keys(
                                                                            Item.compositions
                                                                        ).map(
                                                                            (
                                                                                key3
                                                                            ) => {
                                                                                const Item2 =
                                                                                    {
                                                                                        ...Item
                                                                                            .compositions[
                                                                                            key3
                                                                                        ],
                                                                                    };
                                                                                return (
                                                                                    Item2.name && (
                                                                                        <ProductCardPopup
                                                                                            Item={
                                                                                                Item2
                                                                                            }
                                                                                            product={
                                                                                                product
                                                                                            }
                                                                                            Editproduct={
                                                                                                handleModifyProductOnCLickEvent
                                                                                            }
                                                                                            isSalesModeChange={
                                                                                                isSalesModeChange
                                                                                            }
                                                                                            isCommentProductActive={
                                                                                                isCommentProductActive
                                                                                            }
                                                                                            isWorkflow={
                                                                                                isWorkflow
                                                                                            }
                                                                                        />
                                                                                    )
                                                                                );
                                                                            }
                                                                        )
                                                                    )}
                                                                </React.Fragment>
                                                            );
                                                        }
                                                    )}
                                            </div>
                                        );
                                    })}
                            </Collapse>
                        </div>

                        <button onClick={setIsExpanded} className="view-more ">
                            {isExpanded ? t("Hide details") : t("See details")}
                        </button>
                    </>
                ) : isEditButton && !isSalesModeChange && !isClickAndCollect ? (
                    <button
                        className="view-more"
                        onClick={handleShowDetailOnClickEvent}
                    >
                        {t("Modify")}
                    </button>
                ) : null}
            </div>
        </>
    );
}

ComposedProductCartPopup.propTypes = {
    styles: PropTypes.object,
    product: PropTypes.object.isRequired,
    productClickHandler: PropTypes.func,
    closeOrderSummury: PropTypes.func,
    isRemovedProductDialog: PropTypes.bool,
    isWorkflow: PropTypes.bool,
    isSalesModeChange: PropTypes.bool,
    isDialogCart: PropTypes.bool,
    isPrmDialogCart: PropTypes.bool,
    items: PropTypes.array,
};
