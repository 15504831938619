/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    CATALOGUE_MODE,
    CESAR_LOYALTY,
    LICENCE_PROBLEM,
    NETWORK_PROBLEM,
    TCPOS,
} from "@constants";
import { useSnapshot } from "valtio";

import React from "react";

import { setIsTechProblemModalOpened, store } from "@store";

import { useQueryString } from "@hooks/useQueryString";

import { CustomErrorBoundary } from "@components/CustomErrorBoundary";
import { AuthenticationModalWrapper } from "@components/LocalSettings/AuthenticationModalWrapper";
import { refreshDataNeedTcpos } from "@components/OrderTaking/Helpers/TCPOS";
import { EntitySelectionModalWrapper } from "@components/Synchronization/EntitySelectionModalWrapper";
import {
    setSelectedShopApp,
    syncStore,
} from "@components/Synchronization/store";
import { SyncModal } from "@components/SyncModal";
import { TechnicalProblemModal } from "@components/TechnicalProblemModal";

import { Steps } from "./Steps";

import "@assets/css/global.css";

export function Kiosk(): JSX.Element {
    // const navigate = useNavigate()

    const { isLicenseProblem, isAppSynchronized, posEditor } =
        useSnapshot(store);
    const { isEntitySelectionModalOpened, selectedShopApp } =
        useSnapshot(syncStore);
    // this code is put here for `preview catalog` option
    // in `catalog` app
    // @todo put this code somewhere else
    const { schema, uuidCatalog, franchise_uid, shop_uid, etktype } =
        useQueryString();

    if (schema !== undefined && uuidCatalog) {
        localStorage.setItem("viewADB-schema", schema);
        localStorage.setItem("viewADB-iuudCard", uuidCatalog);
        localStorage.setItem("viewADB-etktype", etktype);
    }
    if (franchise_uid !== undefined && shop_uid) {
        localStorage.setItem("viewADB-franchise_uid", franchise_uid);
        localStorage.setItem("viewADB-shop_uid", shop_uid);
    }

    React.useEffect(() => {
        if (process.env.REACT_APP_MODE !== CATALOGUE_MODE) {
            if (isLicenseProblem) {
                setIsTechProblemModalOpened(isLicenseProblem, LICENCE_PROBLEM);
            } else if (!navigator.onLine) {
                setIsTechProblemModalOpened(!navigator.onLine, NETWORK_PROBLEM);
            }

            // upload card catalog for TCPOS
            if (TCPOS === posEditor) {
                refreshDataNeedTcpos();
            }
        }

        if (selectedShopApp.fidelityType === undefined) {
            setSelectedShopApp({
                ...selectedShopApp,
                fidelityType: CESAR_LOYALTY,
            });
        }
    }, []);

    return (
        <CustomErrorBoundary>
            {process.env.REACT_APP_MODE === CATALOGUE_MODE ||
            isAppSynchronized ? (
                <Steps />
            ) : (
                <div
                    className="d-flex justify-content-center align-items-center h-100"
                    style={{ background: "#191919" }}
                >
                    <SyncModal />
                    <TechnicalProblemModal />
                    <AuthenticationModalWrapper />
                    {isEntitySelectionModalOpened === true ? (
                        <EntitySelectionModalWrapper />
                    ) : (
                        <></>
                    )}
                </div>
            )}
        </CustomErrorBoundary>
    );
}
