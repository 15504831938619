/* eslint-disable */
import { MAX_DIGITS_FRANCE } from "@constants";

import React from "react";
import { Row } from "react-bootstrap";
import { KeyboardInput } from "react-simple-keyboard";

import KioskKeyboard from "@components/common/KioskKeyboard";

import { FormInputs } from "./FormInputs";

type FullFormInputType = {
    activeInputFormItems: any;
    formik: any;
};
export function FullFormInput({
    activeInputFormItems,
    formik,
}: FullFormInputType): JSX.Element {
    const keyboardRef = React.useRef<any>(null);
    const inputRefs = React.useRef<{ [key: string]: HTMLInputElement | null }>(
        {}
    );

    const [inputName, setInputName] = React.useState("default");
    const [isPhoneFieledSelection, setIsPhoneFieledSelection] =
        React.useState<boolean>(true);

    function setInputCaretPosition(
        element: HTMLInputElement | null,
        position: number
    ) {
        if (element && element.setSelectionRange) {
            element.focus();
            element.setSelectionRange(position, position);

            if (position === element.value.length) {
                element.scrollLeft = element.scrollWidth;
            }
        }
    }

    function onChangeAll(
        input: KeyboardInput,
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        if (inputName === "phoneNumber") {
            if (input[inputName].length < MAX_DIGITS_FRANCE + 1) {
                const unformattedPhone = input[inputName].replace(/\s+/g, "");

                if (inputRefs.current[inputName]) {
                    (inputRefs.current[inputName] as any).value = (
                        inputRefs.current[inputName] as any
                    ).value = unformattedPhone;
                    let caretPosition = keyboardRef.current.caretPosition;
                    if (caretPosition !== null) {
                        setInputCaretPosition(
                            inputRefs.current[inputName],
                            keyboardRef.current.caretPosition
                        );
                    }
                }
                formik.setFieldValue(inputName, unformattedPhone, true);
            }
        } else {
            formik.setFieldValue(inputName, input[inputName], true);
            let caretPosition = keyboardRef.current.caretPosition;
            if (caretPosition !== null && inputRefs.current[inputName]) {
                setInputCaretPosition(
                    inputRefs.current[inputName],
                    caretPosition
                );
            }
        }
    }
    return (
        <React.Fragment>
            <div
                style={{
                    maxHeight: "55vh",
                    overflowY: "auto",
                    overflowX: "hidden",
                    scrollbarWidth: "thin",
                    scrollbarColor: "gray #e0e0e0",
                    rowGap: "20px",
                    width: "90vw",
                }}
            >
                {Object.keys(activeInputFormItems).map((group) => (
                    <Row className="m-3" key={group} style={{ width: "100%" }}>
                        {activeInputFormItems[group].map(
                            (field: { name: string; type: string }) => (
                                <div
                                    className={` ${
                                        activeInputFormItems[group].length > 1
                                            ? "col-6"
                                            : "col-12"
                                    }`}
                                    key={field.name}
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    <FormInputs
                                        name={field.name}
                                        type={field.type}
                                        setInputName={setInputName}
                                        formik={formik}
                                        keyboard={keyboardRef}
                                        setIsPhoneFieledSelection={
                                            setIsPhoneFieledSelection
                                        }
                                        inputRef={inputRefs}
                                    />
                                </div>
                            )
                        )}
                    </Row>
                ))}
            </div>
            <KioskKeyboard
                enterClick={formik.handleSubmit}
                keyboard={keyboardRef}
                onChangeAll={onChangeAll}
                inputName={inputName}
                preventMouseDownDefault={true}
                theme={"keyboard__clz hg-theme-default"}
                buttonTheme={[
                    {
                        class: "space-key__clz",
                        buttons: "{space}",
                    },
                ]}
                isPhoneFieledSelection={isPhoneFieledSelection}
                inputRefs={inputRefs}
            />
        </React.Fragment>
    );
}
